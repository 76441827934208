import { createGlobalStyle, Theme, withTheme } from "styled-components";
import { hexToRgb } from "./helpers";

export type GlobalThemeProps = {
  theme: Theme;
};

const cssObject = ({ theme }: GlobalThemeProps) => ({
  ":root": {
    "--ion-background-color": theme.colors.lightPrx,
    "--ion-font-family": "Poppins",

    /** primary * */
    "--ion-color-primary": theme.colors.primaryPrx,
    "--ion-color-primary-rgb": hexToRgb(theme.colors.primaryPrx).join(", "),
    "--ion-color-primary-contrast": theme.colors.whitePrx,
    "--ion-color-primary-contrast-rgb": hexToRgb(theme.colors.whitePrx).join(
      ", "
    ),
    "--ion-color-primary-shade": theme.colors.whitePrx,
    "--ion-color-primary-tint": "#4c8dff",

    /** secondary * */
    "--ion-color-secondary": theme.colors.secondaryPrx,
    "--ion-color-secondary-rgb": hexToRgb(theme.colors.secondaryPrx).join(", "),
    "--ion-color-secondary-contrast": theme.colors.whitePrx,
    "--ion-color-secondary-contrast-rgb": hexToRgb(theme.colors.whitePrx).join(
      ", "
    ),
    "--ion-color-secondary-shade": theme.colors.whitePrx,
    "--ion-color-secondary-tint": "#50c8ff",

    /** tertiary * */
    "--ion-color-tertiary": "#5260ff",
    "--ion-color-tertiary-rgb": "82, 96, 255",
    "--ion-color-tertiary-contrast": "#ffffff",
    "--ion-color-tertiary-contrast-rgb": "255, 255, 255",
    "--ion-color-tertiary-shade": "#4854e0",
    "--ion-color-tertiary-tint": "#6370ff",

    /** success * */
    "--ion-color-success": "#2dd36f",
    "--ion-color-success-rgb": "45, 211, 111",
    "--ion-color-success-contrast": "#ffffff",
    "--ion-color-success-contrast-rgb": "255, 255, 255",
    "--ion-color-success-shade": "#28ba62",
    "--ion-color-success-tint": "#42d77d",

    /** warning * */
    "--ion-color-warning": "#ffc409",
    "--ion-color-warning-rgb": "255, 196, 9",
    "--ion-color-warning-contrast": "#000000",
    "--ion-color-warning-contrast-rgb": "0, 0, 0",
    "--ion-color-warning-shade": "#e0ac08",
    "--ion-color-warning-tint": "#ffca22",

    /** danger * */
    "--ion-color-danger": theme.colors.red74Prx,
    "--ion-color-danger-rgb": "235, 68, 90",
    "--ion-color-danger-contrast": "#ffffff",
    "--ion-color-danger-contrast-rgb": "255, 255, 255",
    "--ion-color-danger-shade": "#cf3c4f",
    "--ion-color-danger-tint": "#ed576b",

    /** dark * */
    "--ion-color-dark": "#222428",
    "--ion-color-dark-rgb": "34, 36, 40",
    "--ion-color-dark-contrast": "#ffffff",
    "--ion-color-dark-contrast-rgb": "255, 255, 255",
    "--ion-color-dark-shade": "#1e2023",
    "--ion-color-dark-tint": "#383a3e",

    /** medium * */
    "--ion-color-medium": theme.colors.gray9CPrx,
    "--ion-color-medium-rgb": hexToRgb(theme.colors.gray9CPrx).join(", "),
    "--ion-color-medium-contrast": theme.colors.whitePrx,
    "--ion-color-medium-contrast-rgb": hexToRgb(theme.colors.whitePrx).join(
      ", "
    ),
    "--ion-color-medium-shade": "#808289",
    "--ion-color-medium-tint": "#9d9fa6",

    /** light * */
    "--ion-color-light": theme.colors.whitePrx,
    "--ion-color-light-rgb": hexToRgb(theme.colors.whitePrx).join(", "),
    "--ion-color-light-contrast": theme.colors.primaryPrx,
    "--ion-color-light-contrast-rgb": "0, 0, 0",
    "--ion-color-light-shade": "#d7d8da",
    "--ion-color-light-tint": "#f5f6f9"
  },
  body: {
    margin: "0 auto"
  },
  button: {
    border: "none",
    background: "none",
    padding: 0,
    margin: 0
  },
  p: {
    fontSize: "14px"
  }
});

const globalStyleObj = createGlobalStyle(cssObject);

export const GlobalStyle = withTheme(globalStyleObj);

import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonInput, IonItem, IonLabel } from "@ionic/react";

export const Item = getStyledElement(IonItem)({
  styles: () => ({
    "--background": "transparent",
    "--min-height": "initial",
    "box-shadow": "none !important",
    "--show-full-highlight": 0,
    "--border-width": 0,
    position: "relative",
    "--inner-padding-end": "0px",
    "--padding-end": "0px",
    "--inner-padding-start": "0px",
    "--padding-start": "0px",
    "--background-hover-opacity": 0,
    "--ripple-color": "transparent",
    "--inner-border-width": "0",
    boxSizing: "border-box"
  })
});

export const InputCustom = getStyledElement(IonInput)({
  styles: ({ theme }) => ({
    "&.legacy-input.sc-ion-input-md-h": {
      "--padding-top": "7px",
      "--padding-bottom": "7px",
      "--padding-start": "13px",
      "--padding-end": "13px"
    },
    "--color": theme.colors.primaryPrx,
    borderRadius: theme.borderRadius[10],
    border: `1px solid ${theme.colors.primaryPrx}`,
    "--background": theme.colors.whitePrx,
    padding: "1px !important",
    ...getMediaStyles({
      fontSize: ["14px", "16px"]
    }),
    "&.has-focus": {
      padding: "0 !important",
      border: `2px solid ${theme.colors.primaryPrx}`
    },
    "&.ion-invalid": {
      borderColor: theme.colors.red74Prx,
      boxShadow: "none"
    },
    ".native-input": {
      borderRadius: theme.borderRadius[10],
      lineHeight: 1.5
    }
  })
});

export const Label = getStyledElement(IonLabel)({
  styles: () => ({
    margin: 0,
    transform: "translateY(0%) scale(0.85) !important"
  })
});

export const InputCustomN = getStyledElement(IonInput)({
  styles: ({ theme }) => ({
    fontSize: "16px",
    "--background": theme.colors.whitePrx,
    "--border-color": theme.colors.primaryPrx,
    "--placeholder-color": theme.colors.primaryPrx,
    "--color": theme.colors.primaryPrx,
    "&.sc-ion-input-md-h:not(.legacy-input)": {
      minHeight: theme.sizes.inputHeight
    },
    ".input-bottom.sc-ion-input-md": {
      "--padding-start": "0",
      paddingTop: "0",
      ".error-text": {
        paddingTop: "4px"
      }
    },
    "&.input-fill-outline": {
      "--border-color": theme.colors.primaryPrx,
      "--border-radius": theme.borderRadius[10],
      "&:hover": {
        "--border-color": theme.colors.primaryPrx
      },
      "&.has-value.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md, &.has-focus.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md":
        { transform: " translateY(-35%) scale(0.8) translateX(-3px)" },
      "&.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md":
        {
          transform: " translateY(100%) scale(1)",
          lineHeight: "16px"
        }
    },
    "&.ion-invalid": {
      "--border-color": theme.colors.red74Prx,
      "--color": theme.colors.red74Prx,
      "--placeholder-color": theme.colors.red74Prx,
      "&.input-fill-outline": {
        "--border-color": theme.colors.red74Prx,
        "&:hover": {
          "--border-color": theme.colors.red74Prx
        }
      }
    }
  })
});

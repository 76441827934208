import { getStyledElement } from "@/helpers";
import { Box } from "@/components";
// import ChatWindow from "../ChatWindow/ChatWindow";
import MainParticipant from "../MainParticipant";
import Participant from "../Participant";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks";
import useVideoContext from "../../hooks/useVideoContext";
import useParticipantsContext from "../../hooks/useParticipantsContext";

const Container = getStyledElement("div")({
  styles: () => ({
    position: "relative",
    height: "100%",
    width: "100%"
  })
});

export default function Room() {
  const { room } = useVideoContext();
  const { localParticipant } = room!;
  const { speakerViewParticipants } = useParticipantsContext();

  return (
    <Container>
      {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
      <ParticipantAudioTracks />
      <MainParticipant />
      {!!speakerViewParticipants.length && (
        <Box
          position="absolute"
          zIndex="11"
          bottom="0"
          padding="20px"
          right="0"
        >
          <Participant participant={localParticipant} isLocalParticipant />
        </Box>
      )}

      {/* <ChatWindow /> */}
    </Container>
  );
}

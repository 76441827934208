import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

export const Grid = getStyledElement(IonGrid)({
  variants: {
    fullWidth: {
      width: "100%"
    },
    responsivePadding: {
      ...getMediaStyles({
        padding: ["20px", "0"]
      })
    }
  }
});

export const Row = getStyledElement(IonRow)();

export const Col = getStyledElement(IonCol)({
  variants: {
    flexCenterY: {
      display: "flex",
      alignItems: "center"
    },
    borderBottomFlex: {
      display: "flex",
      alignItems: "center",
      borderBottomWidth: "2px",
      borderBottomStyle: "solid",
      "&:last-child": { borderBottom: "unset" }
    }
  }
});

/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone
  }
});

export const AxiosClient = {
  instance: axiosInstance,
  get<T = any>(path: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.get(path, config);
  },

  post<T = any>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return axiosInstance.post(path, data, config);
  },

  put<T = any>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return axiosInstance.put(path, data, config);
  },

  patch<T = any>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return axiosInstance.patch(path, data, config);
  },

  delete<T = any>(path: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.delete(path, config);
  },

  options<T = any>(path: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.options(path, config);
  },

  head<T = any>(path: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.head(path, config);
  },

  request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return axiosInstance.request(config);
  }
};

import { LocalAudioTrack } from "twilio-video";
import { useCallback } from "react";
import useIsTrackEnabled from "../useIsTrackEnabled";
import useVideoContext from "../useVideoContext";

export default function useLocalAudioToggle() {
  const { localTracks, getLocalAudioTrack, onError } = useVideoContext();
  const audioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (audioTrack.isEnabled) {
        audioTrack.disable();
      } else {
        audioTrack.enable();
      }
    } else {
      getLocalAudioTrack().catch(onError);
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}

import { Api, AxiosClient } from "@/api";
import { useRouter } from "./useRouter";
import { useToast } from "./useToast";

export const useInitConfirmPhone = () => {
  const router = useRouter();
  const { showError } = useToast();

  return async () => {
    try {
      await AxiosClient.post(Api.initVerificationPhone());

      router.push("confirm-phone");
    } catch (error) {
      showError({ message: "ERRORS.HTTP.GENERAL_ERROR" });
    }
  };
};

import { useCallback, useRef } from "react";

import { ButtonListItem, Icon, Span } from "@/components";
import { videocam, videocamOff } from "ionicons/icons";
import useDevices from "../../hooks/useDevices";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle";

export default function ToggleVideoButton({
  disabled
}: {
  disabled?: boolean;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <ButtonListItem
      color="danger"
      variant="circle"
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || disabled}
    >
      <Span slot="icon-only">
        <Icon
          fontSize="22px"
          color="light"
          icon={isVideoEnabled ? videocam : videocamOff}
        />
      </Span>
    </ButtonListItem>
  );
}

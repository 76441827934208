import { useAppDispatch, useAppSelector } from "../../hook";
import {
  login,
  logout,
  refreshToken,
  registration,
  setAuthFromStorage
} from "../actions";
import { authSlice } from "../reducer";
import { LoginData, RegistrationData } from "../types";

export function useAuth() {
  const dispatch = useAppDispatch();

  return {
    login: (data: LoginData) => dispatch(login(data)),
    registration: (data: RegistrationData) => dispatch(registration(data)),
    logout: () => dispatch(logout()),
    reset: () => dispatch(authSlice.actions.reset()),
    refreshTokens: () => dispatch(refreshToken()),
    setAuthFromStorage: () => dispatch(setAuthFromStorage()),
    ...useAppSelector((state) => state.authReducer)
  };
}

import { getStyledElement } from "@/helpers";

export const P = getStyledElement("p")({
  variants: {
    paragraph: {
      lineHeight: "123%",
      fontWeight: 500,
      letterSpacing: "0.02em",
      margin: "10px 0",
      color: ((theme: any) => {
        return theme.colors.primaryPrx;
      }) as any
    },
    showOnHover: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0,
      margin: 0,
      background: "rgba(0, 0, 0, 0.5)",
      fontSize: "14px",
      fontWeight: "600",
      transitionProperty: "opacity",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-out",
      "&:hover": {
        opacity: 1
      }
    }
  }
});

import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Client, Conversation, Message } from "@twilio/conversations";

import useVideoContext from "../../hooks/useVideoContext";
import { ChatContext } from "../../contexts/chatContext";

export const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();

  const connect = useCallback(
    (token: string) => {
      const client = new Client(token);

      const handleClientInitialized = (state: string) => {
        if (state === "initialized") {
          // @ts-ignore
          window.chatClient = client;
          setChatClient(client);
        } else if (state === "failed") {
          onError(
            new Error(
              "There was a problem connecting to Twilio's conversation service."
            ) as any
          );
        }
      };

      client.on("stateChanged", handleClientInitialized);

      return () => {
        client.off("stateChanged", handleClientInitialized);
      };
    },
    [onError]
  );

  const contextValue = useMemo(
    () => ({
      isChatWindowOpen,
      setIsChatWindowOpen,
      connect,
      hasUnreadMessages,
      messages,
      conversation
    }),
    [
      isChatWindowOpen,
      setIsChatWindowOpen,
      connect,
      hasUnreadMessages,
      messages,
      conversation
    ]
  );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) =>
        setMessages((oldMessages) => [...oldMessages, message]);
      conversation
        .getMessages()
        .then((newMessages) => setMessages(newMessages.items));
      conversation.on("messageAdded", handleMessageAdded);
      return () => {
        conversation.off("messageAdded", handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (room && chatClient) {
      chatClient
        .getConversationByUniqueName(room.sid)
        .then((newConversation) => {
          // @ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(() => {
          onError(
            new Error(
              "There was a problem getting the Conversation associated with this room."
            ) as any
          );
        });
    }
  }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

import DeviceSelectionDialog from "@/pages/video/components/DeviceSelectionDialog";
import { ButtonListItem, Icon, Span } from "@/components";
import { settings } from "ionicons/icons";
import { useState } from "react";

export default function SettingsMenuButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonListItem
        color="medium"
        variant="circle"
        onClick={() => setIsOpen(true)}
      >
        <Span slot="icon-only">
          <Icon fontSize="28px" color="light" icon={settings} />
        </Span>
      </ButtonListItem>
      <DeviceSelectionDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

import React from "react";
import {
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonNote
} from "@ionic/react";
import { getStyledElement, getMediaStyles } from "@/helpers";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Span } from "../span";
import { Item } from "../input/components";

const ButtonDate = getStyledElement(IonDatetimeButton)({
  styles: ({ theme }) => ({
    "--ion-color-step-300": theme.colors.whitePrx,
    "--ion-text-color": theme.colors.primaryPrx,
    display: "flex",
    gap: "10px",
    "&.ion-invalid": {
      "&::part(native)": {
        borderColor: theme.colors.red74Prx
      }
    },
    "&::part(native)": {
      display: "flex",
      padding: "9px 15px",
      border: `1px solid ${theme.colors.primaryPrx}`,
      borderRadius: theme.borderRadius[10],
      margin: 0,
      ...getMediaStyles({
        fontSize: ["14px", "16px"]
      }),
      lineHeight: "1.4",
      "white-space": "nowrap"
    }
  })
});

type DateTimeButtonProps = {
  value?: string;
  error?: string;
  touched?: boolean;
  onIonChange?: (e: any) => void;
  id: string;
};

export const DateTimeButton: React.FC<DateTimeButtonProps> = ({
  value,
  error,
  touched,
  onIonChange,
  id
}) => {
  const { t } = useTranslation();

  return (
    <Item
      className={`${!error && "ion-valid"} ${
        error && touched && "ion-invalid"
      } ${touched && "ion-touched"}`}
    >
      <ButtonDate
        datetime={id}
        className={`${!error && "ion-valid"} ${
          error && touched && "ion-invalid"
        } ${touched && "ion-touched"}`}
      >
        <Span width="100px" slot="date-target">
          {value ? (
            dayjs(value).format("DD.MM.YYYY")
          ) : (
            <Span opacity="0.6">{t("INPUTS.DATE")}</Span>
          )}
        </Span>
        <Span width="42px" slot="time-target">
          {value ? (
            dayjs(value).format("HH:mm")
          ) : (
            <Span opacity="0.6">{t("INPUTS.TIME")}</Span>
          )}
        </Span>
      </ButtonDate>
      <IonModal keepContentsMounted>
        <IonDatetime onIonChange={onIonChange} id={id} hourCycle="h23" />
      </IonModal>
      <IonNote slot="error">{error}</IonNote>
    </Item>
  );
};

/* eslint-disable no-param-reassign */
import { AppStorage } from "@/services/storage";
import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@/core/constants";
import {
  login,
  logout,
  refreshToken,
  registration,
  setAuthFromStorage
} from "./actions";
import { authState } from "./state";

function getUserIdFromToken(token: string): number {
  if (!token) {
    return 0;
  }

  const { sub } = jwtDecode<{ sub: string }>(token);

  return Number(sub);
}

export const authSlice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    reset(state) {
      state.userId = 0;
      state.isAuthenticated = false;

      AppStorage.remove(ACCESS_TOKEN_KEY);
      AppStorage.remove(REFRESH_TOKEN_KEY);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setAuthFromStorage.fulfilled, (state, action) => {
      state.userId = getUserIdFromToken(action.payload.accessToken);
      state.isAuthenticated = !!action.payload.accessToken;
    });

    builder.addMatcher(
      (action) =>
        [
          registration.pending.type,
          login.pending.type,
          refreshToken.pending.type,
          logout.pending.type
        ].includes(action.type),
      (state) => {
        state.isLoading = true;
      }
    );

    builder.addMatcher(
      (action) =>
        [
          registration.fulfilled.type,
          login.fulfilled.type,
          refreshToken.fulfilled.type
        ].includes(action.type),
      (state, action) => {
        state.userId = getUserIdFromToken(action.payload.accessToken);
        state.errorCode = "";
        state.isAuthenticated = true;
        state.isLoading = false;

        AppStorage.set(ACCESS_TOKEN_KEY, action.payload.accessToken);
        AppStorage.set(REFRESH_TOKEN_KEY, action.payload.refreshToken);
      }
    );

    builder.addMatcher(
      (action) => [logout.fulfilled.type].includes(action.type),
      (state) => {
        state.userId = 0;
        state.isAuthenticated = false;
        state.isLoading = false;

        AppStorage.remove(ACCESS_TOKEN_KEY);
        AppStorage.remove(REFRESH_TOKEN_KEY);
      }
    );

    builder.addMatcher(
      (action) => [logout.rejected.type].includes(action.type),
      (state, action) => {
        state.errorCode = (action.error as { message: string }).message;
        state.isLoading = false;
      }
    );

    builder.addMatcher(
      (action) =>
        [
          registration.rejected.type,
          login.rejected.type,
          refreshToken.rejected.type
        ].includes(action.type),
      (state, action) => {
        state.isLoading = false;
        state.errorCode = (action.error as { message: string }).message;

        AppStorage.remove(ACCESS_TOKEN_KEY);
        AppStorage.remove(REFRESH_TOKEN_KEY);
      }
    );
  }
});

import { getDeviceInfo } from "@/helpers";
import { useState, useEffect } from "react";

// This returns the type of the value that is returned by a promise resolution
type ThenArg<T> = T extends PromiseLike<infer U> ? U : never;

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState<
    ThenArg<ReturnType<typeof getDeviceInfo>>
  >({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: true,
    hasVideoInputDevices: true
  });

  useEffect(() => {
    const getDevices = () =>
      getDeviceInfo().then((devices) => {
        setDeviceInfo(devices);
      });

    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return deviceInfo;
}

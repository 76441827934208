export enum Specialities {
  CARDIOLOG = "CARDIOLOG",
  COSMETOLOG = "COSMETOLOG",
  DERMATOLOG = "DERMATOLOG",
  DIETOLOG = "DIETOLOG",
  GINECOLOG = "GINECOLOG",
  HEPATOLOG = "HEPATOLOG",
  HEMATOLOG = "HEMATOLOG",
  INFECTIONIST = "INFECTIONIST",
  MEDIC_DE_FAMILIE = "MEDIC_DE_FAMILIE",
  NEFROLOG = "NEFROLOG",
  NEUROLOG = "NEUROLOG",
  PEDIATRU = "PEDIATRU",
  PSIHIATRU = "PSIHIATRU",
  PSIHOLOG = "PSIHOLOG",
  PSIHOTERAPEUT = "PSIHOTERAPEUT",
  PULMONOLOG = "PULMONOLOG",
  ORTOPED = "ORTOPED",
  UROLOG = "UROLOG",
  VENEROLOG = "VENEROLOG",
  ENDOCRINOLOG = "ENDOCRINOLOG",
  GASTROLOG = "GASTROLOG",
  ORL = "ORL",
  KINETOTERAPEUT = "KINETOTERAPEUT",
  REABILITOLOG = "REABILITOLOG",
  ALERGOLOG = "ALERGOLOG",
  PROCTOLOG = "PROCTOLOG",
  TRIHOLOG = "TRIHOLOG",
  STOMATOLOG = "STOMATOLOG",
  ACUPUNCTURIST = "ACUPUNCTURIST",
  ANDROLOG = "ANDROLOG",
  CHIRURG = "CHIRURG",
  CHIRURG_ESTETICIAN = "CHIRURG_ESTETICIAN",
  CHIRURG_PLASTICIAN = "CHIRURG_PLASTICIAN",
  FITOTERAPEUT = "FITOTERAPEUT",
  FIZIOTERAPEUT = "FIZIOTERAPEUT",
  FLEBOLOG = "FLEBOLOG",
  IMAGIST = "IMAGIST",
  INTERNIST_TERAPEUT = "INTERNIST_TERAPEUT",
  MAMOLOG = "MAMOLOG",
  NEUROCHIRURG = "NEUROCHIRURG",
  OFTALMOLOG = "OFTALMOLOG",
  ONCOLOG = "ONCOLOG",
  PODOLOG = "PODOLOG",
  REPRODUCTOLOG = "REPRODUCTOLOG",
  REUMATOLOG = "REUMATOLOG",
  SEXOLOG = "SEXOLOG",
  MASAJIST = "MASAJIST",
  TERAPEUT_MANUAL = "TERAPEUT_MANUAL",
  ECOGRAFIST = "ECOGRAFIST",
  LOGOPED = "LOGOPED",
  FARMACIST = "FARMACIST"
}

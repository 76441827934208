import { useMemo } from "react";
import { LocalAudioTrack } from "twilio-video";

import { Box, Select, Span } from "@/components";
import { AppStorage } from "@/services/storage";

import AudioLevelIndicator from "../AudioLevelIndicator";
import { SELECTED_AUDIO_INPUT_KEY } from "../../constants";
import useDevices from "../../hooks/useDevices";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack";
import useVideoContext from "../../hooks/useVideoContext";

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(
    (track) => track.kind === "audio"
  ) as LocalAudioTrack;
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId ||
    mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    AppStorage.set(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  const options = useMemo(
    () =>
      audioInputDevices.map(({ deviceId, label }) => ({
        value: deviceId,
        label
      })),
    [audioInputDevices]
  );

  return (
    <Box variant="flexRow" justifyContent="space-between" alignItems="flex-end">
      {audioInputDevices.length > 1 ? (
        <Select
          value={localAudioInputDeviceId || ""}
          onIonChange={(e: CustomEvent) =>
            replaceTrack(e.detail.value as string)
          }
          options={options}
        >
          Audio Input
        </Select>
      ) : (
        <Span pb="10px">
          {localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}
        </Span>
      )}
      <Box pb="5px" px="10px">
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Box>
    </Box>
  );
}

import { FC, PropsWithChildren } from "react";
import { IonModal } from "@ionic/react";
import { getStyledElement, getMediaStyles } from "@/helpers";
import { useAppTheme, useMedia } from "@/hooks";
import { DialogProps } from "@/core/types";
import { Span } from "../span";
import { H2 } from "../h";
import { Box } from "../box";
import { Button } from "../button";

const Modal = getStyledElement(IonModal)({
  styles: ({ theme }) => ({
    ...getMediaStyles({
      "--border-radius": ["0", theme.borderRadius[10]]
    }),
    "--ion-background-color": theme.colors.whitePrx,
    "--height": "auto"
  })
});

const ModalDesk = getStyledElement(Modal)({
  styles: () => ({
    ...getMediaStyles({
      "--width": ["100%", "auto"],
      "--height": ["100%", "auto"]
    })
  })
});

const Close = getStyledElement("button")({
  styles: ({ theme }) => ({
    height: "35px",
    width: "35px",
    fontSize: "18px",
    marginLeft: "5px",
    color: theme.colors.gray9DPrx,
    "&:hover": {
      border: `1px solid ${theme.colors.grayF4Prx}`,
      borderRadius: "7px"
    }
  })
});

export const Dialog: FC<DialogProps & PropsWithChildren> = ({
  isOpen,
  showFooter = true,
  isModalSheet = true,
  closeText = "Anuleaza",
  submitText = "Salveaza",
  title,
  onSave = () => {},
  onClose = () => {},
  onDismiss = () => {},
  children,
  styles
}) => {
  const theme = useAppTheme();
  const { isMobile } = useMedia();

  return isMobile && isModalSheet ? (
    <Modal
      isOpen={isOpen}
      mode="ios"
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      key="modal-mobile"
      onDidDismiss={onDismiss}
    >
      {title && (
        <H2 px="15px" fontSize="20px">
          {title}
        </H2>
      )}
      {children}
    </Modal>
  ) : (
    <ModalDesk isOpen={isOpen} onDidDismiss={onDismiss} key="modal-desktop">
      {title && (
        <Box
          variant="flexRow"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`2px solid ${theme.colors.grayF4Prx}`}
          p="15px 10px 10px"
        >
          <H2 m="0" fontSize="20px">
            {title}
          </H2>
          <Close type="button" onClick={onClose}>
            <Span>X</Span>
          </Close>
        </Box>
      )}
      <Box
        overflow="auto"
        maxHeight={["auto", "600px"]}
        height="100%"
        {...styles}
      >
        {children}
      </Box>
      {showFooter && (
        <Box
          variant="flexRow"
          justifyContent="flex-end"
          gridGap="15px"
          p="15px 10px"
          borderTop={`2px solid ${theme.colors.grayF4Prx}`}
        >
          <Button type="button" color="medium" onClick={onClose}>
            {closeText}
          </Button>
          <Button type="button" color="secondary" onClick={onSave}>
            {submitText}
          </Button>
        </Box>
      )}
    </ModalDesk>
  );
};

import { FieldInputProps } from "formik";
import React from "react";
import { InputCustomN } from "./components";

type InputProps = {
  error?: string;
  touched?: boolean;
  readonly?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
  type?: string;
} & FieldInputProps<string | number | null>;

const InputN: React.FC<InputProps> = ({
  error,
  touched,
  placeholder,
  readonly,
  value,
  onChange,
  label,
  name,
  id,
  type,
  onBlur
}) => {
  return (
    <InputCustomN
      id={id}
      className={`${error && touched ? "ion-invalid" : ""} ${
        touched ? "ion-touched" : ""
      }`}
      name={name}
      type={type}
      value={value}
      onIonInput={onChange}
      readonly={readonly}
      label={label}
      labelPlacement="floating"
      fill="outline"
      placeholder={placeholder}
      onBlur={onBlur}
      errorText={error}
    />
  );
};

export default React.memo(InputN);

import { getStyledElement } from "@/helpers";
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup
} from "@ionic/react";
import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import { Box } from "../box";
import { Span } from "../span";

const Accordion = getStyledElement(IonAccordion)({
  styles: ({ theme }) => ({
    boxShadow: "none",
    borderRadius: `${theme.borderRadius[20]} !important`
  })
});

const Header = getStyledElement(IonItem)({
  styles: ({ theme }) => ({
    "--padding-start": "10px",
    "--padding-right": "10px",
    "--min-height": "auto",
    minWidth: "105px",
    "&::part(native)": {
      "--min-height": "auto",
      fontSize: "15px"
    },
    "ion-icon": {
      margin: "0",
      fontSize: theme.fontSize[16]
    }
  })
});

const Radio = getStyledElement(IonRadio)({
  styles: () => ({
    marginLeft: 0,
    width: "100%",
    padding: "5px 0",
    fontSize: "15px"
  })
});

const RadioGroup = getStyledElement(IonRadioGroup)({
  styles: () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "20px"
  })
});

const AccordionContent = getStyledElement(Box)({
  styles: ({ theme }) => ({
    backgroundColor: theme.colors.primaryPrx,
    paddingBottom: "10px",
    color: theme.colors.whitePrx
  })
});

const List = getStyledElement(IonList)({
  styles: ({ theme }) => ({
    overflow: "auto",
    backgroundColor: theme.colors.primaryPrx,
    padding: 0,
    marginBottom: "5px",
    "&::-webkit-scrollbar": {
      width: "12px"
    },

    "&::-webkit-scrollbar-thumb": {
      "border-radius": "10px",
      "background-color": theme.colors.lightPrx,
      "background-clip": "padding-box",
      border: "4px solid rgba(0, 0, 0, 0)"
    }
  })
});

type SelectTimeAccordionProps = {
  onDateChange: (event: CustomEvent<{ value: string }>) => any;
  timeOptions: string[];
  isDisabled?: boolean;
  maxHeight?: string;
};

const SelectTimeAccordion: React.FC<SelectTimeAccordionProps> = ({
  isDisabled,
  onDateChange,
  maxHeight = "340px",
  timeOptions = []
}) => {
  const [value, setValue] = useState<string>();
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const toggleAccordion = () => {
    if (!accordionGroup.current) {
      return;
    }

    const nativeEl = accordionGroup.current;

    if (nativeEl.value === "second") {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = "second";
    }
  };

  const onSelect = (
    event: CustomEvent<{
      value: string;
    }>
  ) => {
    onDateChange(event);
    setValue(event.detail.value);
    toggleAccordion();
  };

  return (
    <IonAccordionGroup ref={accordionGroup}>
      <Accordion value="first" disabled={isDisabled}>
        <Header slot="header" color="primary">
          <IonLabel>
            {value ? (
              dayjs(value).format("HH:mm")
            ) : (
              <Span letterSpacing="3.6px" fontWeight="600">
                --:--
              </Span>
            )}
          </IonLabel>
        </Header>
        <AccordionContent slot="content">
          <List lines="none" maxHeight={maxHeight}>
            <RadioGroup
              value={value}
              allowEmptySelection
              onIonChange={onSelect}
            >
              {timeOptions.map((option) => (
                <Radio
                  key={option}
                  color="light"
                  mode="ios"
                  slot="end"
                  value={option}
                >
                  {dayjs(option).format("HH:mm")}
                </Radio>
              ))}
            </RadioGroup>
          </List>
        </AccordionContent>
      </Accordion>
    </IonAccordionGroup>
  );
};

export default React.memo(SelectTimeAccordion);

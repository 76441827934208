import { IonNote, IonSelectOption } from "@ionic/react";
import { FC, PropsWithChildren, useRef } from "react";
import { Item, Label } from "../input/components";
import { SelectCustom } from "./components";

type SelectValue = null | string | number | (string | number)[];

type SelectProps = {
  value?: SelectValue;
  multiple?: boolean;
  options?: { value: null | string | number; label: any }[];
  name?: string;
  onIonChange?: (event: CustomEvent<any>) => void;
  placeholder?: string;
  error?: string;
  touched?: boolean;
};

export const Select: FC<SelectProps & PropsWithChildren> = ({
  value,
  onIonChange,
  children,
  error,
  touched,
  name,
  placeholder,
  options = [],
  multiple
}) => {
  const selectRef = useRef(null);

  return (
    <Item
      width="100%"
      className={`${!error && "ion-valid"} ${
        error && touched && "ion-invalid"
      } ${touched && "ion-touched"}`}
    >
      <Label position="stacked">{children}</Label>
      <SelectCustom
        ref={selectRef}
        className={`${!error && "ion-valid"} ${
          error && touched && "ion-invalid"
        } ${touched && "ion-touched"}`}
        multiple={multiple}
        mode="ios"
        interface="popover"
        interfaceOptions={{
          cssClass: "select-popover",
          size: "auto",
          reference: selectRef
        }}
        placeholder={children || placeholder}
        onIonChange={onIonChange}
        name={name}
        value={value}
      >
        {options.map((option) => (
          <IonSelectOption key={option.value} value={option.value}>
            {option.label}
          </IonSelectOption>
        ))}
      </SelectCustom>
      <IonNote slot="error">{error}</IonNote>
    </Item>
  );
};

import { theme } from "@/theme";
import { TStyle } from "./getStyledElement";

export const getMediaStyles = (css: {
  [key: string]: (string | TStyle | undefined)[];
}) => {
  const styles = Object.entries(css).reduce(
    (acc, [key, [sm = {}, md = {}, lg = {}, xl = {}]]) => {
      acc.sm = {
        ...acc.sm,
        [key]: sm
      };
      acc.md = {
        ...acc.md,
        [key]: md
      };
      acc.lg = {
        ...acc.lg,
        [key]: lg
      };
      acc.xl = {
        ...acc.xl,
        [key]: xl
      };

      return acc;
    },
    { sm: {}, md: {}, lg: {}, xl: {} }
  );

  return {
    [`@media screen and (max-width: ${theme.breakpoints.md})`]: styles.sm,
    // ...(styles.sm
    //   ? {
    //       [`@media screen and (min-width: ${theme.breakpoints.sm})`]: styles.sm
    //     }
    //   : {}),
    ...(styles.md
      ? {
          [`@media screen and (min-width: ${theme.breakpoints.md})`]: styles.md
        }
      : {}),
    ...(styles.lg
      ? {
          [`@media screen and (min-width: ${theme.breakpoints.lg})`]: styles.lg
        }
      : {}),
    ...(styles.xl
      ? {
          [`@media screen and (min-width: ${theme.breakpoints.xl})`]: styles.xl
        }
      : {})
  };
};

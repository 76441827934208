/* eslint-disable no-nested-ternary */
import { checkmarkOutline } from "ionicons/icons";
import React, { FC } from "react";
import { Box } from "../box";
import { Icon } from "../icon";
import { Span } from "../span";
import { StepperWrapper, StepperCounter, StepperItem } from "./components";

interface StepperProps {
  steps: { title: string; component: any }[];
  selectedStep: number;
}

const Stepper: FC<StepperProps> = ({ steps, selectedStep = 0 }) => {
  return (
    <Box>
      <StepperWrapper>
        {steps.map(({ title }, index) => (
          <StepperItem
            key={`${title}-${index}`}
            className={
              selectedStep === index
                ? "active"
                : selectedStep > index
                ? "completed"
                : ""
            }
          >
            <StepperCounter className="counter">
              <Icon className="check" icon={checkmarkOutline} />
              <Span className="count">{index + 1}</Span>
            </StepperCounter>
            {title}
          </StepperItem>
        ))}
      </StepperWrapper>
      <Box>{steps[selectedStep]?.component()}</Box>
    </Box>
  );
};

export default React.memo(Stepper);

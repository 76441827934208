import { ButtonListItem, Icon, Span } from "@/components";
import { mic, micOff } from "ionicons/icons";

import useLocalAudioToggle from "../../hooks/useLocalAudioToggle";
import useDevices from "../../hooks/useDevices";

export default function ToggleAudioButton({
  disabled
}: {
  disabled?: boolean;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { hasAudioInputDevices } = useDevices();

  return (
    <ButtonListItem
      color="danger"
      variant="circle"
      onClick={toggleAudioEnabled}
      disabled={!hasAudioInputDevices || disabled}
    >
      <Span slot="icon-only">
        <Icon
          fontSize="22px"
          color="light"
          icon={isAudioEnabled ? mic : micOff}
        />
      </Span>
    </ButtonListItem>
  );
}

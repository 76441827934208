import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonItem } from "@ionic/react";

export const Chip = getStyledElement(IonItem)({
  styles: ({ theme }) => ({
    "--border-radius": theme.borderRadius[20],
    "--min-height": 0,
    "--show-full-highlight": 0,
    "--border-width": 0,
    "--background-hover-opacity": 0,
    "--inner-border-width": "0",
    "--padding-top": "8px",
    "--padding-bottom": "8px",
    ...getMediaStyles({
      "--background": [theme.colors.lightPrx, theme.colors.whiteBluePrx],
      "--color": [theme.colors.primaryPrx, theme.colors.whitePrx]
    })
  })
});

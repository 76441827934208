import styled from "styled-components";

export const StepperWrapper = styled.ol`
  display: flex;
  justify-content: space-between;
  padding: 0;
  flex-wrap: nowrap;
`;

export const StepperItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  padding: 0 5px;
  font-weight: bold;

  &::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }

  &::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  &.active {
    color: var(--ion-color-primary);

    .counter {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-light);
    }
  }

  &.completed {
    color: var(--ion-color-secondary);

    .counter {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-light);

      .check {
        display: block;
      }

      .count {
        display: none;
      }
    }
  }

  &.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid var(--ion-color-secondary);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  &:first-child::before {
    content: none;
  }

  &:last-child::after {
    content: none;
  }
`;

export const StepperCounter = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;

  .check {
    display: none;
    font-size: 30px;
  }

  &.completed {
    background-color: #4bb543;
  }
`;

import { useGetUser, useUpdateMeMutation } from "@/store/user/hooks";
import { useEffect } from "react";
import { useToast } from "./useToast";
import { useHttpErrorHandler } from "./useHttpErrorHandler";

export const useUpdateMe = () => {
  const [updateUser, { error, data }] = useUpdateMeMutation();
  const { data: user } = useGetUser();
  const { showSuccess } = useToast();

  useHttpErrorHandler(error);

  useEffect(() => {
    if (!data) return;

    showSuccess({ message: "TOASTS.SAVED_DATA" });
  }, [data]);

  return { updateUser, user };
};

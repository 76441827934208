import { useAuth } from "@/store/auth/hooks";
import { useGetUserQuery } from "./apiHooks";

export const useGetUser = () => {
  const { isAuthenticated } = useAuth();

  return useGetUserQuery(undefined, {
    skip: !isAuthenticated,
    pollingInterval: 900000
  });
};

import React from "react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack
} from "twilio-video";

import { getStyledElement } from "@/helpers";
import { Box, Icon, Span } from "@/components";
import { personCircle } from "ionicons/icons";
import AudioLevelIndicator from "../AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import useVideoContext from "../../hooks/useVideoContext";

const AvatarContainer = getStyledElement("div")({
  styles: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1
  })
});

const ReconnectingContainer = getStyledElement("div")({
  styles: () => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1
  })
});

const InfoContainer = getStyledElement("div")({
  styles: () => ({
    position: "absolute",
    zIndex: 2,
    height: "100%",
    width: "100%"
  })
});

const Container = getStyledElement("div")({
  styles: () => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%"
  })
});

const Identity = getStyledElement("div")({
  styles: () => ({
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "0.1em 0.3em 0.1em 0",
    display: "inline-flex",
    "& svg": {
      marginLeft: "0.3em"
    },
    marginRight: "0.4em",
    alignItems: "center"
  })
});

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children
}: MainParticipantInfoProps) {
  const { room } = useVideoContext();
  const { localParticipant } = room!;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );
  const screenSharePublication = publications.find((p) =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <Container>
      <InfoContainer>
        <Box variant="flexRow">
          <Identity>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Span>
              {participant.identity}
              {isLocal && " (Eu)"}
            </Span>
          </Identity>
          <NetworkQualityLevel participant={participant} />
        </Box>
      </InfoContainer>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <AvatarContainer>
          <Icon fontSize="180px" color="light" icon={personCircle} />
        </AvatarContainer>
      )}
      {isParticipantReconnecting && (
        <ReconnectingContainer>
          <Span color="#fff">Reconectare...</Span>
        </ReconnectingContainer>
      )}
      {children}
    </Container>
  );
}

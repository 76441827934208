import { getStyledElement } from "@/helpers";
import { IonTextarea } from "@ionic/react";
import { FieldInputProps } from "formik";

type TextareaProps = {
  error?: string;
  touched?: boolean;
  readonly?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
  type?: string;
} & FieldInputProps<string | null>;

export const TextareaCustom = getStyledElement(IonTextarea)({
  styles: ({ theme }) => ({
    "--background": theme.colors.whitePrx,
    "--border-color": theme.colors.primaryPrx,
    "--placeholder-color": theme.colors.primaryPrx,
    "--color": theme.colors.primaryPrx,
    "&.textarea-fill-outline": {
      "--border-color": theme.colors.primaryPrx,
      "--border-radius": theme.borderRadius[10],
      "&:hover": {
        "--border-color": theme.colors.primaryPrx
      },
      ".textarea-bottom": {
        "--padding-start": "0"
      },
      "&.has-value.textarea-label-placement-floating.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md, &.has-focus.textarea-label-placement-floating.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md":
        { transform: " translateY(-35%) scale(0.80) translateX(-3px)" }
    },
    "&.ion-invalid": {
      "--border-color": theme.colors.red74Prx,
      "&.input-fill-outline": {
        "--border-color": theme.colors.red74Prx,
        "&:hover": {
          "--border-color": theme.colors.red74Prx
        }
      }
    }
  })
});

export const Textarea: React.FC<TextareaProps> = ({
  error,
  touched,
  placeholder,
  readonly,
  value,
  onChange,
  label,
  name,
  id,
  onBlur
}) => {
  return (
    <TextareaCustom
      className={`${error && touched ? "ion-invalid" : ""} ${
        touched ? "ion-touched" : ""
      }`}
      id={id}
      value={value}
      name={name}
      onIonInput={onChange}
      readonly={readonly}
      autoGrow
      label={label}
      labelPlacement="floating"
      fill="outline"
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
};

import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { DayChangeEvent } from "@/core/types";
import { Box } from "../box";
import { Dialog } from "../dialog";
import { InputCustomN } from "../input/components";
import Calendar from "../calendar";

type DateButtonProps = {
  name?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  onChange?: (e: any) => void;
  label: string;
  id: string;
};

const SelectDateN: React.FC<DateButtonProps> = ({
  value,
  name,
  error,
  touched,
  label,
  onChange = () => {},
  id
}) => {
  const [date, setDate] = useState(value || "");
  const input = useRef<HTMLInputElement>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [formatedDate, setFormatedDate] = useState("");

  const dispatchInputEvent = () => {
    if (!input.current) return;

    const event = new Event("input", { bubbles: true });
    // eslint-disable-next-line no-underscore-dangle
    const tracker = (input.current as any)._valueTracker;

    if (tracker) {
      tracker.setValue(date);
    }

    input.current.dispatchEvent(event);
  };

  const onSelectDate = (event: DayChangeEvent) => {
    const dayjsDate = dayjs()
      .year(event.year)
      .month(event.month)
      .date(event.day);
    const formated = dayjsDate.format("DD/MM/YYYY");
    const isoDate = dayjsDate.toISOString();

    setFormatedDate(formated);
    setDate(isoDate);
    setIsOpenModal(false);
  };

  const fillDefaultValues = () => {
    let format = "";
    if (value) {
      const dayjsDate = dayjs(value);
      format = value ? dayjsDate.format("DD/MM/YYYY") : "";
    }

    setDate(value || "");
    setFormatedDate(format);
  };

  useEffect(() => {
    if (value !== date) dispatchInputEvent();
  }, [date]);

  useEffect(() => {
    fillDefaultValues();
  }, [value]);

  return (
    <Box>
      <input
        type="hidden"
        ref={input}
        value={date}
        name={name}
        onInput={onChange}
      />
      <InputCustomN
        id={id}
        className={`${error && touched ? "ion-invalid" : ""} ${
          touched ? "ion-touched" : ""
        }`}
        type="text"
        value={formatedDate}
        readonly
        label={label}
        labelPlacement="floating"
        fill="outline"
        placeholder="__/__/____"
        errorText={error}
        name={name}
        onClick={() => setIsOpenModal(true)}
      />
      <Dialog
        isOpen={isOpenModal}
        onDismiss={() => setIsOpenModal(false)}
        title=""
        isModalSheet
        showFooter={false}
      >
        <Box variant="flexRow" justifyContent="center" m="15px">
          <Calendar onDateChange={onSelectDate} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default React.memo(SelectDateN);

import { ListParams } from "@/core/types/listParams";
import { useAuth } from "@/store/auth/hooks";
import { useGetMeAppointmentsQuery } from "./apiHooks";

export const useGetMeAppointments = (params: ListParams) => {
  const { isAuthenticated } = useAuth();

  return useGetMeAppointmentsQuery(params, {
    skip: !isAuthenticated
  });
};

import React, { FC } from "react";
import { DayChangeEvent } from "@/core/types";
import { Week } from "./types";
import { Box } from "../box";
import { DayButton } from "./components";

const CalendarDays: FC<{
  arrayOfDays: Week[];
  selectDate: (date: DayChangeEvent) => void;
  selectedDate?: DayChangeEvent;
  isDateEnabled: (event: DayChangeEvent) => boolean;
  min?: DayChangeEvent;
  max?: DayChangeEvent;
}> = ({ arrayOfDays, selectDate, selectedDate, isDateEnabled, min, max }) => {
  const isDisabled = (day: DayChangeEvent) => {
    if (
      min &&
      day.year === min.year &&
      day.month === min.month &&
      day.day < min.day
    )
      return true;

    if (
      max &&
      day.year === max.year &&
      day.month === max.month &&
      day.day > max.day
    )
      return true;

    return !isDateEnabled(day);
  };

  return (
    <>
      {arrayOfDays.map((week, index) => {
        return (
          <Box
            variant="flexRow"
            width="100%"
            flexWrap="wrap"
            key={`week-${index}`}
          >
            {week.dates.map((day, i) => {
              return (
                <Box
                  variant="flexRow"
                  flex="1 1 50px"
                  height="50px"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  key={`week-${index}-day-${i}`}
                >
                  {day.isCurrentMonth && (
                    <DayButton
                      variant={
                        selectedDate &&
                        selectedDate.day === day.day &&
                        selectedDate.month === day.month &&
                        selectedDate.year === day.year
                          ? "active"
                          : ""
                      }
                      disabled={isDisabled(day)}
                      type="button"
                      onClick={() => selectDate(day)}
                    >
                      {day.day}
                    </DayButton>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

export default React.memo(CalendarDays);

import { Dayjs } from "dayjs";
import React, { FC, useContext } from "react";
import { SpinnerContext } from "@/contexts";
import { Box } from "../box";
import {
  CalendarButton,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight
} from "./components";
import { Spinner } from "../spinner";
import { Span } from "../span";

const CalendarHeader: FC<{
  onPrev: () => void;
  onNext: () => void;
  onNextYear: () => void;
  onPrevYear: () => void;
  yearDisabled: boolean;
  currentMonth: Dayjs;
}> = ({
  onPrev,
  onNext,
  currentMonth,
  onNextYear,
  onPrevYear,
  yearDisabled
}) => {
  const isLoading = useContext(SpinnerContext);
  const dateFormat = "MMMM YYYY";

  return (
    <Box variant="flexRow" alignItems="center">
      <Box variant="flexRow" justifyContent="flex-end">
        {!yearDisabled && (
          <CalendarButton
            color="light"
            type="button"
            onClick={() => {
              onPrevYear();
            }}
          >
            <ChevronDoubleLeft slot="icon-only" />
          </CalendarButton>
        )}
        <CalendarButton
          color="light"
          type="button"
          onClick={() => {
            onPrev();
          }}
        >
          <ChevronLeft slot="icon-only" />
        </CalendarButton>
      </Box>
      <Box
        fontSize="16px"
        variant="flexRow"
        flex="1"
        justifyContent="center"
        alignItems="center"
      >
        <Span position="relative">
          {currentMonth.format(dateFormat)}
          {isLoading && (
            <Spinner
              position="absolute"
              right="-25px"
              top="2px"
              width="18px"
              height="18px"
              color="primary"
            />
          )}
        </Span>
      </Box>
      <Box variant="flexRow" justifyContent="flex-start">
        <CalendarButton
          color="light"
          type="button"
          onClick={() => {
            onNext();
          }}
        >
          <ChevronRight slot="icon-only" />
        </CalendarButton>
        {!yearDisabled && (
          <CalendarButton
            color="light"
            type="button"
            onClick={() => {
              onNextYear();
            }}
          >
            <ChevronDoubleRight slot="icon-only" />
          </CalendarButton>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(CalendarHeader);

import { LocalVideoTrack } from "twilio-video";
import useVideoContext from "@/pages/video/hooks/useVideoContext";
import VideoTrack from "@/pages/video/components/VideoTrack";
import LocalAudioLevelIndicator from "@/pages/video/components/LocalAudioLevelIndicator";
import { Icon, Span } from "@/components";
import { getStyledElement } from "@/helpers";
import { personCircle } from "ionicons/icons";

const Container = getStyledElement("div")({
  styles: () => ({
    position: "relative",
    height: 0,
    overflow: "hidden",
    paddingTop: `${(9 / 16) * 100}%`,
    background: "black"
  })
});

const InnerContainer = getStyledElement("div")({
  styles: () => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  })
});

const AvatarContainer = getStyledElement("div")({
  styles: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1
  })
});

const IdentityContainer = getStyledElement("div")({
  styles: () => ({
    position: "absolute",
    bottom: 0,
    zIndex: 1
  })
});

const Identity = getStyledElement("div")({
  styles: () => ({
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "0.18em 0.3em",
    margin: 0,
    display: "flex",
    alignItems: "center"
  })
});

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    (track) => !track.name.includes("screen") && track.kind === "video"
  ) as LocalVideoTrack;

  return (
    <Container>
      <InnerContainer>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <AvatarContainer>
            <Icon fontSize="90px" color="light" icon={personCircle} />
          </AvatarContainer>
        )}
      </InnerContainer>

      <IdentityContainer>
        <Identity>
          <LocalAudioLevelIndicator />
          <Span>{identity}</Span>
        </Identity>
      </IdentityContainer>
    </Container>
  );
}

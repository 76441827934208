import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonSelect } from "@ionic/react";
import { Icon } from "../icon";

export const SelectAccountActions = getStyledElement(IonSelect)({
  styles: () => ({
    maxWidth: "max-content",
    "min-height": "0",
    ...getMediaStyles({
      fontSize: ["12px", "14px"]
    }),
    "&::part(icon)": {
      display: "none"
    },
    "&::part(text)": {
      display: "none"
    },
    "&::part(label)": {
      margin: 0
    }
  })
});

export const SelectAccountActionsAvatar = getStyledElement(Icon)({
  styles: ({ theme }) => ({
    color: "inherit !important",
    zIndex: 999,
    top: "10px",
    left: "10px",
    ...getMediaStyles({
      width: ["15px", "20px"],
      fill: [theme.colors.primaryPrx, theme.colors.whitePrx]
    })
  })
});

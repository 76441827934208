import MainParticipantInfo from "../MainParticipantInfo";
import ParticipantTracks from "../ParticipantTracks";
import useMainParticipant from "../../hooks/useMainParticipant";
import useSelectedParticipant from "../../hooks/useSelectedParticipant";
import useVideoContext from "../../hooks/useVideoContext";

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const { localParticipant } = room!;
  const [selectedParticipant] = useSelectedParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant &&
    mainParticipant !== localParticipant
      ? "high"
      : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
}

import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { GetAppointmentsParams } from "@/core/types/api/getAppointmentsParams";
import { UpdateAppointment } from "@/core/types/api/updateAppointment";
import { RefundPayment } from "@/core/types/api/refundPayment";
import { PrescriptionCreateDto } from "@/core/types/prescriptionCreate";
import { PrescriptionUpdateDto } from "@/core/types/prescriptionUpdate";
import { List } from "@/core/types/list";
import { Appointment, AppointmentFull, CreateAppointment } from "./types";
import { axiosBaseQuery } from "../baseQuery";

export const appointmentsApi = createApi({
  reducerPath: "Appointments",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Appointments", "Appointment"],
  endpoints: (build) => ({
    getMeAppointments: build.query<List<Appointment>, GetAppointmentsParams>({
      query: (params) => ({
        url: Api.meAppointments(),
        method: "get",
        params
      }),
      providesTags: () => [{ type: "Appointments" }],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) return newItems;

        return {
          items: [...currentCache.items, ...newItems.items],
          total: newItems.total
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      }
    }),

    getUserAppointment: build.query<
      AppointmentFull,
      { appointmentId: number; userId: number }
    >({
      query: ({ appointmentId, userId }) => ({
        url: Api.getUserAppointment(userId, appointmentId),
        method: "get"
      }),
      providesTags: (_, __, { appointmentId }) => [
        { type: "Appointment", id: appointmentId }
      ]
    }),
    appointmentUseMedicalInsurance: build.mutation<
      boolean,
      { appointmentId: number; userId: number; insuranceId: number }
    >({
      query: ({ appointmentId, userId, insuranceId }) => ({
        url: Api.appointmentUseMedicalInsurance(userId, appointmentId),
        method: "POST",
        data: { insuranceId }
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointment", id: appointmentId }
      ]
    }),

    getAppointments: build.query<
      List<Appointment>,
      GetAppointmentsParams & { userId: number }
    >({
      query: ({
        userId,
        ...params
      }: GetAppointmentsParams & { userId: number }) => ({
        url: Api.appointments(userId),
        method: "get",
        params
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) return newItems;

        return {
          items: [...currentCache.items, ...newItems.items],
          total: newItems.total
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: () => [{ type: "Appointments", id: "LIST" }]
    }),
    getAppointment: build.query<
      AppointmentFull,
      { id: number; userId: number }
    >({
      query: ({ id, userId }) => ({
        url: Api.appointment(userId, id),
        method: "get"
      }),
      providesTags: (_, __, { id }) => {
        return [{ type: "Appointment", id }];
      }
    }),
    addAppointment: build.mutation<Appointment, CreateAppointment>({
      query: ({ userId, ...body }) => ({
        url: Api.appointments(userId),
        method: "POST",
        data: body
      })
    }),
    deleteAppointment: build.mutation<void, { userId: number; id: number }>({
      query: ({ id, userId }) => ({
        url: Api.appointment(userId, id),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, { id }) => [{ type: "Appointment", id }]
    }),
    updateAppointment: build.mutation<void, UpdateAppointment>({
      query: ({ id, userId, ...body }) => ({
        url: Api.appointment(userId, id),
        method: "PUT",
        data: body
      }),
      invalidatesTags: (_, __, { id }) => {
        return [{ type: "Appointment", id }];
      }
    }),
    finishAppointment: build.mutation<
      Appointment,
      { id: number; userId: number }
    >({
      query: ({ id, userId }) => ({
        url: Api.appointmentFinish(userId, id),
        method: "PUT"
      }),
      invalidatesTags: (_, __, { id }) => [{ type: "Appointment", id }]
    }),
    refundPayment: build.mutation<void, RefundPayment>({
      query: ({ appointmentId, ...body }) => ({
        url: Api.paymentRefund(),
        method: "POST",
        data: body
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointment", id: appointmentId }
      ]
    }),

    createPrescription: build.mutation<
      boolean,
      PrescriptionCreateDto & { userId: number }
    >({
      query: ({ userId, ...dto }) => ({
        url: Api.prescriptions(userId),
        method: "post",
        data: dto
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointment", id: appointmentId }
      ]
    }),
    updatePrescription: build.mutation<
      boolean,
      PrescriptionUpdateDto & {
        id: number;
      }
    >({
      query: ({ userId, id, appointmentId, ...dto }) => ({
        url: Api.prescription(userId, id),
        method: "put",
        data: dto
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointment", id: appointmentId }
      ]
    })
  })
});

export enum TransactionStatus {
  Ok = "OK",
  Failed = "FAILED",
  Created = "CREATED",
  Pending = "PENDING",
  Declined = "DECLINED",
  Reserved = "RESERVED",
  Autoreversed = "AUTOREVERSED",
  Timeout = "TIMEOUT"
}

export const TRANSACTION_STATUSE_LABELS = {
  [TransactionStatus.Ok]: {
    icon: "check",
    message: "Plata s-a finisat cu success!"
  },
  [TransactionStatus.Failed]: {
    icon: "error",
    message: "Plata a esuat!"
  },
  [TransactionStatus.Created]: {
    icon: "warning",
    message: "Plata a fost inregistrata in sistem!"
  },
  [TransactionStatus.Pending]: {
    icon: "warning",
    message: "Plata in curs de asteptare!"
  },
  [TransactionStatus.Declined]: {
    icon: "error",
    message: "Plata respinsa!"
  },
  [TransactionStatus.Reserved]: {
    icon: "warning",
    message: "Plata rezervata!"
  },
  [TransactionStatus.Timeout]: {
    icon: "error",
    message: "Timpul alocat tranzactiei a expirat!"
  },
  [TransactionStatus.Autoreversed]: {
    icon: "error",
    message: "Plata anulata!"
  }
};

import { useEffect } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { useToast } from "./useToast";

export const useHttpErrorHandler = (error: SerializedError | undefined) => {
  const { showError } = useToast();

  useEffect(() => {
    if (!error) return;

    showError({ message: `ERRORS.HTTP.${error.message}` });
  }, [error]);
};

import React from "react";
import { useTranslation } from "react-i18next";

import { Language } from "@/core/constants";
import SelectN from "../select/SelectN";

type SelectLanguageProps = {
  touched: boolean;
  error?: string;
  id: string;
};

const SelectLanguage: React.FC<SelectLanguageProps> = ({
  touched,
  error,
  ...field
}) => {
  const { t } = useTranslation();

  const options = Object.values(Language).map((lang) => ({
    value: lang,
    label: t(lang.toUpperCase())
  }));

  return (
    <SelectN
      {...field}
      multiple
      touched={touched}
      error={error}
      options={options}
      label={t("INPUTS.LANGUAGES")}
    />
  );
};

export default React.memo(SelectLanguage);

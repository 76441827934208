import { FC } from "react";
import { Api, BASE_URL } from "@/api";
import { TStyle } from "@/helpers";
import { Img } from "../img";

export const DoctorImage: FC<
  {
    variant?: string;
    gender?: string;
    avatarId?: number;
  } & React.HTMLAttributes<HTMLElement> &
    TStyle
> = ({ variant, gender, avatarId, ...props }) => {
  const url = avatarId
    ? `${BASE_URL}${Api.avatar(avatarId)}`
    : `assets/doctor${gender}.png`;

  return <Img {...props} variant={variant} src={url} alt="doctor" />;
};

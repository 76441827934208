import { IonBadge } from "@ionic/react";

import { getStyledElement } from "@/helpers";

export const Badge = getStyledElement(IonBadge)({
  variants: {
    rounded: {
      borderRadius: "20px",
      padding: "7px 10px",
      margin: "0 2px 5px 2px",
      wordBreak: "break-word",
      whiteSpace: "break-spaces"
    }
  }
});

import React from "react";
import styled, {
  CSSObject,
  InterpolationFunction,
  Theme
} from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  variant,
  compose,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
  FlexboxProps,
  GridProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  flex,
  system
} from "styled-system";
import { props } from "@styled-system/should-forward-prop";

type TextDecorationProps = {
  textDecoration?: string;
};

type TextOverflowProps = {
  textOverflow?: string;
};

type VisibilityProps = {
  visibility?: string;
};

export type TStyle =
  | (SpaceProps &
      ColorProps &
      LayoutProps &
      TypographyProps &
      FlexboxProps &
      GridProps &
      BackgroundProps &
      BorderProps &
      PositionProps &
      ShadowProps &
      FlexboxProps &
      TextDecorationProps &
      TextOverflowProps &
      VisibilityProps)
  | {
      [key: string]: string | number | ((prop: any) => any) | TStyle;
    };

const otherStyles = system({
  textDecoration: true,
  transform: true,
  textOverflow: true,
  visibility: true
});

const composedStyles = compose(
  space,
  color,
  layout,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  flex,
  otherStyles
);

interface TVariants {
  [key: string]: TStyle | InterpolationFunction<Theme>;
}

type ElementProps = {
  styles?:
    | TemplateStringsArray
    | CSSObject
    | InterpolationFunction<
        { theme: Theme } & TStyle & Record<string, unknown>
      >;
  variants?: TVariants;
  propExceptions?: string[];
};

export const getStyledElement =
  <T>(element: keyof JSX.IntrinsicElements | React.ComponentType<T>) =>
  ({ styles = {}, variants = {}, propExceptions = [] }: ElementProps = {}) => {
    const fn = styled(element).withConfig({
      shouldForwardProp: (prop) => {
        return ![
          ...propExceptions,
          ...props.filter(
            (item) => !["color", "position", "slot", "size"].includes(item)
          ),
          "textOverflow",
          "whiteSpace"
        ].includes(String(prop));
      }
    });

    return fn(styles, variant({ variants }), composedStyles);
  };

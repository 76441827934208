import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useDoctorSpecialities = (specialities: string[]) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      specialities?.reduce(
        (acc, name) =>
          acc
            ? `${t(`SPECIALITIES_LIST.${name}.NAME`)} / ${acc}`
            : t(`SPECIALITIES_LIST.${name}.NAME`),
        ""
      ),
    [specialities, t]
  );
};

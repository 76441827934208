import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { PrescriptionCreateDto } from "@/core/types/prescriptionCreate";
import { PrescriptionUpdateDto } from "@/core/types/prescriptionUpdate";
import { axiosBaseQuery } from "../baseQuery";
import { Prescription } from "../appointments/types";

export const prescriptionApi = createApi({
  reducerPath: "Prescriptions",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Prescriptions", "Appointments"],
  endpoints: (build) => ({
    getPrescription: build.query<Prescription, { id: number; userId: number }>({
      query: ({ id, userId }) => ({
        url: Api.prescription(userId, id),
        method: "get"
      }),
      providesTags: (_, __, { id }) => [{ type: "Prescriptions", id }]
    }),
    createPrescription: build.mutation<
      boolean,
      PrescriptionCreateDto & { userId: number }
    >({
      query: ({ userId, ...dto }) => ({
        url: Api.prescriptions(userId),
        method: "post",
        data: dto
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointments", id: appointmentId },
        { type: "Appointments", id: "LIST" }
      ]
    }),
    updatePrescription: build.mutation<
      boolean,
      PrescriptionUpdateDto & {
        id: number;
      }
    >({
      query: ({ userId, id, appointmentId, ...dto }) => ({
        url: Api.prescription(userId, id),
        method: "put",
        data: dto
      }),
      invalidatesTags: (_, __, { appointmentId, id }) => [
        { type: "Prescriptions", id },
        { type: "Appointments", id: appointmentId },
        { type: "Appointments", id: "LIST" }
      ]
    }),
    deletePrescriptionFile: build.mutation<
      void,
      { fileId: number; prescriptionId: number; userId: number }
    >({
      query: ({ fileId, userId }) => ({
        url: Api.file(fileId),
        method: "DELETE",
        params: { userId }
      }),
      invalidatesTags: (_, __, { prescriptionId }) => [
        { type: "Prescriptions", id: prescriptionId }
      ]
    })
  })
});

import styled from "styled-components";

export const GradientCircle = styled("div")`
  position: absolute;
  width: 600px;
  height: 600px;
  top: 800px;
  right: -100px;
  z-index: -2;

  background: linear-gradient(
    203.1deg,
    #a8e0f2 24.12%,
    rgb(70 202 83 / 42%) 86.55%
  );
  filter: blur(100px);
`;

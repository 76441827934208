import { ButtonListItem, Icon, Span } from "@/components";
import { call } from "ionicons/icons";
import { useHistory } from "react-router";
import useVideoContext from "../../hooks/useVideoContext";

export default function EndCallButton() {
  const { room } = useVideoContext();
  const history = useHistory();

  const close = () => {
    room!.disconnect();
    history.push({ search: "" });
  };

  return (
    <ButtonListItem color="danger" variant="circle" onClick={() => close()}>
      <Span slot="icon-only">
        <Icon fontSize="22px" color="light" icon={call} />
      </Span>
    </ButtonListItem>
  );
}

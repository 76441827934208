import { getStyledElement } from "@/helpers";
import { IonCard, IonCardContent } from "@ionic/react";

export const Card = getStyledElement(IonCard)({
  styles: (props) => {
    return {
      borderRadius: "25px",
      boxShadow: "none",
      margin: 0,
      border: `1px solid ${
        (props.borderColor as string) || props.theme.colors.whiteBluePrx
      }`
    };
  },
  variants: {
    shadowed: (theme) => ({
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      borderColor: "unset",
      border: "unset",
      boxShadow: theme.shadow.neutral
    })
  }
});

export const CardContent = getStyledElement(IonCardContent)();

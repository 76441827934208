import { Participant } from "twilio-video";
import { getStyledElement } from "@/helpers";
import { Box } from "@/components";
import useParticipantNetworkQualityLevel from "../../hooks/useParticipantNetworkQualityLevel";

const OuterContainer = getStyledElement("span")({
  styles: () => ({
    width: "2em",
    height: "2em",
    padding: "0.9em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 0.5)"
  })
});

const InnerContainer = getStyledElement("span")({
  styles: () => ({
    display: "flex",
    alignItems: "flex-end",
    "& div": {
      width: "2px",
      marginRight: "1px",
      "&:not(:last-child)": {
        borderRight: "none"
      }
    }
  })
});

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel({
  participant
}: {
  participant: Participant;
}) {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  if (networkQualityLevel === null) return null;

  return (
    <OuterContainer>
      <InnerContainer>
        {BARS_ARRAY.map((level) => (
          <Box
            key={level}
            height={`${STEP * (level + 1)}px`}
            background={
              networkQualityLevel > level ? "white" : "rgba(255, 255, 255, 0.2)"
            }
          />
        ))}
      </InnerContainer>
    </OuterContainer>
  );
}

import React, { FC, useEffect, useState } from "react";
import dayjs from "@/helpers/dayjs";
import { DayChangeEvent, MonthChangeEvent } from "@/core/types";
import { Dayjs, ManipulateType } from "dayjs";
import { Box } from "../box";
import { Week } from "./types";
import CalendarHeader from "./CalendarHeader";
import CalendarWeekDays from "./CalendarWeekDays";
import CalendarDays from "./CalendarDays";

type CalendarProps = {
  onDateChange: (event: DayChangeEvent) => any;
  onMonthChange?: (month: MonthChangeEvent) => any;
  isDateEnabled?: (event: DayChangeEvent) => boolean;
  min?: DayChangeEvent;
  max?: DayChangeEvent;
  value?: string;
  yearDisabled?: boolean;
};

const Calendar: FC<CalendarProps> = ({
  onDateChange,
  min,
  max,
  value,
  yearDisabled = false,
  onMonthChange = () => {},
  isDateEnabled = () => true
}) => {
  const now = dayjs(value || undefined);

  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState<Week[]>([]);
  const [selectedDate, setSelectedDate] = useState<DayChangeEvent>();

  const next = (timeUnit: ManipulateType) => {
    const month = currentMonth.month();
    const year = currentMonth.year();

    if (max && (year > max.year || (year === max.year && month >= max.month)))
      return;

    const plus = currentMonth.add(1, timeUnit);

    setCurrentMonth(plus);
    onMonthChange({ month: plus.month(), year: plus.year() });
    setSelectedDate(undefined);
  };

  const prev = (timeUnit: ManipulateType) => {
    const month = currentMonth.month();
    const year = currentMonth.year();

    if (min && (year < min.year || (year === min.year && month <= min.month)))
      return;

    const minus = currentMonth.subtract(1, timeUnit);

    setCurrentMonth(minus);
    onMonthChange({ month: minus.month(), year: minus.year() });
    setSelectedDate(undefined);
  };

  const selectDate = (date: DayChangeEvent) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  const formateDateObject = (date: Dayjs): DayChangeEvent => {
    const clonedObject = { ...date.toObject() };

    const formatedObject: DayChangeEvent = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday()
    };

    return formatedObject;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonthNr = currentMonth.add(1, "month").month();

    const allDates: Week[] = [];
    let weekDates: DayChangeEvent[] = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonthNr) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);

      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }

    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  useEffect(() => {
    if (!value) {
      setSelectedDate(undefined);

      return;
    }

    const month = dayjs(value).month();
    const year = dayjs(value).year();
    const day = dayjs(value).date();

    setSelectedDate({ month, year, day });
  }, [value]);

  return (
    <Box width="350px" minWidth="350px" height="405px">
      <CalendarHeader
        yearDisabled={yearDisabled}
        onPrev={() => prev("month")}
        onNext={() => next("month")}
        onNextYear={() => next("year")}
        onPrevYear={() => prev("year")}
        currentMonth={currentMonth}
      />
      <CalendarWeekDays now={now} />
      <CalendarDays
        arrayOfDays={arrayOfDays}
        selectDate={selectDate}
        selectedDate={selectedDate}
        isDateEnabled={isDateEnabled}
        min={min}
      />
    </Box>
  );
};

export default React.memo(Calendar);

import { Box, Grid, Row } from "@/components";
import { getStyledElement } from "@/helpers";
import EndCallButton from "../EndCallButton";
import useRoomState from "../../hooks/useRoomState";
import ToggleAudioButton from "../ToggleAudioButton";
// import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../ToggleVideoButton";
import SettingsMenuButton from "../SettingsMenuButton";

const Container = getStyledElement("footer")({
  styles: ({ theme }) => ({
    backgroundColor: theme.colors.lightPrx,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    display: "flex",
    padding: "0 1.43em",
    zIndex: 10
  })
});

export default function MenuBar() {
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";

  return (
    <Container>
      <Grid>
        <Row justifyContent="center">
          <Box variant="flexRow" gridGap="7px">
            <ToggleAudioButton disabled={isReconnecting} />
            <ToggleVideoButton disabled={isReconnecting} />
            {/* {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" && (
              <ToggleChatButton />
            )} */}
            <SettingsMenuButton />
            <EndCallButton />
          </Box>
        </Row>
      </Grid>
    </Container>
  );
}

import { getStyledElement, getMediaStyles } from "@/helpers";
import { useAppTheme, useMedia, useRouter } from "@/hooks";
import { arrowBackOutline } from "ionicons/icons";
import React, { FC, PropsWithChildren } from "react";
import { menuController } from "@ionic/core/components";
import { Box } from "../box";
import { H1 } from "../h";
import { Icon } from "../icon";

const ButtonBack = getStyledElement("button")();
const BackIcon = getStyledElement(Icon)({
  styles: ({ theme }) => ({
    color: theme.colors.primaryPrx
  })
});
const Title = getStyledElement(H1)({
  styles: () => ({
    margin: "0",
    ...getMediaStyles({
      fontSize: ["24px", "32px"]
    })
  })
});

const DashboardPageHeader: FC<
  { showBack?: boolean; backUrlFragmentNr?: number } & PropsWithChildren
> = ({ showBack, backUrlFragmentNr = -1, children }) => {
  const router = useRouter();
  const theme = useAppTheme();
  const { isMobile } = useMedia();

  const goBack = async () => {
    const [, , dashboard, ...rest] = router.routeInfo.pathname.split("/");

    if (rest.length === 1) {
      if (isMobile) await menuController.open("dashboard");

      return;
    }

    const restPath = rest.slice(0, backUrlFragmentNr).join("/");

    router.push(`${dashboard}/${restPath}`);
  };

  return (
    <Box
      variant="flexRow"
      justifyContent="space-between"
      alignItems="flex-start"
      mb="10px"
    >
      <ButtonBack
        mr="5px"
        display={!showBack ? ["block", "none"] : "block"}
        type="button"
        onClick={() => goBack()}
      >
        <BackIcon size="large" icon={arrowBackOutline} />
      </ButtonBack>
      <Title color={theme.colors.primaryPrx}>{children}</Title>
    </Box>
  );
};

export default React.memo(DashboardPageHeader);

import { createContext } from "react";
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room
} from "twilio-video";

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalAudioTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalAudioTrack>;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
}

export const VideoContext = createContext<IVideoContext>(null!);

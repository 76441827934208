import { getStyledElement, getMediaStyles } from "@/helpers";

export const Box = getStyledElement("div")({
  variants: {
    flexCol: {
      display: "flex",
      flexDirection: "column"
    },
    flexRow: {
      display: "flex",
      flexDirection: "row"
    },
    flexColCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    flexColXStartToRowYCenter: {
      display: "flex",
      ...getMediaStyles({
        flexDirection: ["column", "row"],
        alignItems: ["flex-start", "center"]
      })
    }
  }
});

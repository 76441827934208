import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppStorage } from "@/services/storage";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@/core/constants";
import { Tokens } from "@/core/types";

export const setAuthFromStorage = createAsyncThunk(
  "auth/setAuthFromStorage",
  async (): Promise<Tokens> => {
    const [accessToken, refreshToken] = await Promise.all([
      AppStorage.get(ACCESS_TOKEN_KEY),
      AppStorage.get(REFRESH_TOKEN_KEY)
    ]);

    return { accessToken, refreshToken };
  }
);

import React from "react";
import Video from "twilio-video";
import { Box, LinkTo } from "@/components";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from "@ionic/react";
import { getStyledElement } from "@/helpers";
import { useHistory } from "react-router";

const CardTitle = getStyledElement(IonCardTitle)({
  styles: () => ({
    fontSize: "32px"
  })
});

export default function UnsupportedBrowserWarning({
  children
}: {
  children: React.ReactElement;
}) {
  const history = useHistory();

  if (!Video.isSupported) {
    return (
      <Box variant="flexRow" justifyContent="center" marginTop="2.5em">
        <IonCard>
          <IonCardHeader>
            <CardTitle>Browserul sau contextul nu sunt acceptate</CardTitle>
            <IonCardSubtitle>
              Vă rugăm să deschideți aplicația în unul din{" "}
              <LinkTo
                href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                target="_blank"
                rel="noopener"
              >
                browserele selectate
              </LinkTo>
              <br />
              Dacă utilizați un browser acceptat, vă rugăm să vă asigurați că
              această aplicație este difuzată într-un{" "}
              <LinkTo
                href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
                target="_blank"
                rel="noopener"
              >
                context securizat
              </LinkTo>{" "}
              (e.g. https or localhost).
              <br />
              <br />
              <LinkTo onClick={() => history.goBack()}>Mergi înapoi</LinkTo>
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      </Box>
    );
  }

  return children;
}

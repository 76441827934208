import { AuthState } from "./types";

export const authState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  userId: 0,
  accessToken: "",
  refreshToken: "",
  errorCode: ""
};

import i18n from "@/i18n";
import { useIonRouter } from "@ionic/react";

export const useRouter = () => {
  const router = useIonRouter();

  const push: typeof router.push = (...args) => {
    const [segment, ...rest] = args;

    router.push(`/${i18n.language}/${segment}`, ...rest);
  };

  return { ...router, push };
};

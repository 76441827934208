import { SkeletonContext } from "@/contexts";
import { getStyledElement } from "@/helpers";
import {
  IonSkeletonText,
  useIonViewDidEnter,
  useIonViewWillEnter
} from "@ionic/react";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState
} from "react";

const SkeletonText = getStyledElement(IonSkeletonText)();

const Wrapper = getStyledElement("div")({
  styles: () => ({
    animation: "fadeIn 0.5s linear",
    display: "grid",
    width: "fit-content",
    height: "fit-content",
    "animation-timing-function": "ease-out",
    "animation-fill-mode": "both",
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0
      },
      "100%": {
        opacity: 1
      }
    }
  })
});

interface SkeletonProps {
  styles?: any;
}

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  styles = {},
  children
}) => {
  const isLoading = useContext(SkeletonContext);

  if (isLoading) return <SkeletonText animated {...styles} />;

  return <Wrapper>{(children || null) as ReactElement}</Wrapper>;
};

interface SkeletonProviderProps {
  onLoad: () => Promise<any>;
  deps?: any[];
}

export const SkeletonProvider: FC<PropsWithChildren<SkeletonProviderProps>> = ({
  children,
  onLoad,
  deps
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useIonViewWillEnter(() => {
    setIsLoading(true);
  }, deps);

  useIonViewDidEnter(() => {
    onLoad().finally(() => setIsLoading(false));
  }, deps);

  return (
    <SkeletonContext.Provider value={isLoading}>
      {children}
    </SkeletonContext.Provider>
  );
};

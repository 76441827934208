import { DayChangeEvent, MonthChangeEvent } from "@/core/types";
import dayjs from "@/helpers/dayjs";
import { useEffect, useState } from "react";
import { useGetDoctorWorkingDays } from "./useGetDoctorWorkingDays";

export const useDoctorCalendar = (doctorId: number, value?: string) => {
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [timestampsMap, setTimestampsMap] = useState<{
    [key: string]: string[];
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getWorkingDays } = useGetDoctorWorkingDays();

  const min = {
    year: dayjs().year(),
    month: dayjs().month(),
    day: dayjs().date()
  };

  const isDateEnabled = ({ day }: DayChangeEvent) => {
    return !!timestampsMap[day]?.length;
  };

  const onDateChange = async ({ isCurrentDay, day }: DayChangeEvent) => {
    let hours = timestampsMap[day];

    if (isCurrentDay) {
      hours = timestampsMap[day].filter((date) => {
        return dayjs(date).isAfter(dayjs().add(2, "h"));
      });
    }

    setTimeOptions(hours);
  };

  const onMonthChange = async ({ month, year }: MonthChangeEvent) => {
    setIsLoading(true);
    setTimeOptions([]);
    setTimestampsMap({});

    const day =
      month === dayjs().month() && year === dayjs().year() ? dayjs().date() : 1;

    const startDate = dayjs()
      .year(year)
      .month(month)
      .date(day)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();

    const { data: days = {} } = await getWorkingDays(
      {
        doctorId,
        startDate
      },
      false
    );

    setTimestampsMap(days);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!doctorId) return;

    const month = dayjs(value).month();
    const year = dayjs(value).year();

    onMonthChange({ month, year });
  }, [doctorId]);

  useEffect(() => {
    if (!value || !doctorId) return;

    const month = dayjs(value).month();
    const year = dayjs(value).year();
    const day = dayjs(value).year();

    onDateChange({ month, year, day });
  }, [value]);

  return {
    timeOptions,
    min,
    isDateEnabled,
    onDateChange,
    onMonthChange,
    isLoading
  };
};

import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-primary);
`;

const EmptyPage: FC<PropsWithChildren> = ({
  children = "Lista este goală."
}) => {
  return (
    <Wrapper>
      <img src="assets/images/empty.png" width="250px" height="250px" alt="" />
      <Text>{children}</Text>
    </Wrapper>
  );
};

export default EmptyPage;

import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { ConnectOptions } from "twilio-video";
import { isMobile } from "@/helpers";
import { ErrorCallback } from "../../types";
import { SelectedParticipantProvider } from "../../hooks/useSelectedParticipant";

import useHandleRoomDisconnection from "../../hooks/useHandleRoomDisconnection";
import useHandleTrackPublicationFailed from "../../hooks/useHandleTrackPublicationFailed";
import useLocalTracks from "../../hooks/useLocalTracks";
import useRestartAudioTrackOnDeviceChange from "../../hooks/useRestartAudioTrackOnDeviceChange";
import useRoom from "../../hooks/useRoom";
import useVideoContext from "../../hooks/useVideoContext";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle";
import { VideoContext } from "../../contexts/videoContext";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

function AttachVisibilityHandler() {
  const { room } = useVideoContext();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const shouldRepublishVideoOnForeground = useRef(false);

  useEffect(() => {
    if (room && isMobile) {
      const handleVisibilityChange = () => {
        // We don't need to unpublish the local video track if it has already been unpublished
        if (document.visibilityState === "hidden" && isVideoEnabled) {
          shouldRepublishVideoOnForeground.current = true;
          toggleVideoEnabled();

          // Don't publish the local video track if it wasn't published before the app was backgrounded
        } else if (shouldRepublishVideoOnForeground.current) {
          shouldRepublishVideoOnForeground.current = false;
          toggleVideoEnabled();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [isVideoEnabled, room, toggleVideoEnabled]);

  return null;
}

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => {}
}: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      onError(error);
    },
    [onError]
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const videoContextValue = useMemo(
    () => ({
      room,
      localTracks,
      isConnecting,
      onError: onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      getAudioAndVideoTracks
    }),
    [
      room,
      localTracks,
      isConnecting,
      onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      getAudioAndVideoTracks
    ]
  );

  return (
    <VideoContext.Provider value={videoContextValue}>
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

import { getStyledElement } from "@/helpers";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight
} from "react-icons/hi";
import { Theme } from "styled-components";
import { Button } from "../button";

export const ChevronDoubleLeft = getStyledElement(HiChevronDoubleLeft)({
  styles: () => ({
    width: "30px",
    height: "30px"
  })
});

export const ChevronDoubleRight = getStyledElement(HiChevronDoubleRight)({
  styles: () => ({
    width: "30px",
    height: "30px"
  })
});

export const ChevronLeft = getStyledElement(HiChevronLeft)({
  styles: () => ({
    width: "30px",
    height: "30px"
  })
});

export const ChevronRight = getStyledElement(HiChevronRight)({
  styles: () => ({
    width: "30px",
    height: "30px"
  })
});

export const DayButton = getStyledElement("button")({
  styles: () => ({
    position: "absolute",
    width: "35px",
    height: "35px",
    lineHeight: 1,
    color: "#000",
    fontSize: "16px",
    "&[disabled]": {
      color: "#babab6"
    }
  }),
  variants: {
    disabled: {},
    active: {
      borderRadius: "50%",
      color: (({ colors }: Theme) => colors.whitePrx) as any,
      background: (({ colors }: Theme) => colors.primaryPrx) as any
    }
  }
});

export const CalendarButton = getStyledElement(Button)({
  styles: ({ theme }) => ({
    "&::part(native)": {
      "--padding-top": "8px",
      "--padding-bottom": "8px",
      "--padding-start": "8px",
      "--padding-end": "8px"
    },
    "--background": theme.colors.lightPrx,
    color: theme.colors.primaryPrx
  })
});

/* eslint-disable radix */
import { Storage as IonicStorage, Drivers } from "@ionic/storage";

let storage: IonicStorage;

const create = (name = "__heroDocDB") => {
  storage = new IonicStorage({
    name,
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });

  return storage.create();
};

const set = async (key: string, val: any) => {
  storage.set(key, val);
};

const get = async (key: string) => {
  return storage.get(key);
};

const remove = async (key: string) => {
  await storage.remove(key);
};

const clear = async () => {
  await storage.clear();
};

const setObject = async (key: string, id: string, val: any) => {
  const all = await storage.get(key);
  const objIndex = await all.findIndex(
    (a: any) => parseInt(a.id) === parseInt(id)
  );

  all[objIndex] = val;

  set(key, all);
};

const removeObject = async (key: string, id: string) => {
  const all = await storage.get(key);
  const objIndex = await all.findIndex(
    (a: any) => parseInt(a.id) === parseInt(id)
  );

  all.splice(objIndex, 1);

  set(key, all);
};

const getObject = async (key: string, id: string) => {
  const all = await storage.get(key);

  return all.filter((a: any) => parseInt(a.id) === parseInt(id))[0];
};

export const AppStorage = {
  create,
  set,
  get,
  remove,
  clear,
  setObject,
  getObject,
  removeObject
};

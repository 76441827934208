import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonSelect } from "@ionic/react";

export const SelectCustom = getStyledElement(IonSelect)({
  styles: ({ theme }) => ({
    color: theme.colors.primaryPrx,
    borderRadius: theme.borderRadius[10],
    border: `1px solid ${theme.colors.primaryPrx}`,
    "background-color": theme.colors.whitePrx,
    "--padding-start": "15px !important",
    "--padding-end": "15px",
    "--padding-top": "9px",
    "--padding-bottom": "9px",
    "--placeholder-opacity": "0.5",
    "min-height": "0",
    width: "auto",
    ...getMediaStyles({
      fontSize: ["14px", "16px"]
    }),
    "&.has-focus": {
      padding: "0 !important",
      border: `2px solid ${theme.colors.primaryPrx}`
    },
    "&.ion-invalid": {
      borderColor: theme.colors.red74Prx,
      boxShadow: "none"
    },
    ".native-input": {
      borderRadius: theme.borderRadius[10],
      lineHeight: 1.5
    },
    "&::part(icon)": {
      display: "none !important"
    }
  })
});

export const SelectCustomN = getStyledElement(IonSelect)({
  styles: ({ theme }) => ({
    fontSize: "16px",
    "--background": theme.colors.whitePrx,
    "--border-color": theme.colors.primaryPrx,
    "--placeholder-color": theme.colors.primaryPrx,
    color: theme.colors.primaryPrx,
    "&:not(.legacy-input)": {
      minHeight: theme.sizes.inputHeight
    },
    "&.select-fill-outline": {
      "--border-color": theme.colors.primaryPrx,
      "--border-radius": theme.borderRadius[10],
      "&:hover": {
        "--border-color": theme.colors.primaryPrx
      },
      ".select-bottom": {
        "--padding-start": "0"
      }
    },
    "&.has-value::part(label), &.select-expanded::part(label)": {
      transform: " translateY(-35%) scale(0.8) translateX(-4px)"
    },
    "&:not(.has-value, .select-expanded)::part(label)": {
      transform: " translateY(100%) scale(1)",
      lineHeight: "16px"
    },
    "&.ion-invalid": {
      "--border-color": theme.colors.red74Prx,
      "--color": theme.colors.red74Prx,
      "--placeholder-color": theme.colors.red74Prx,
      "&.select-fill-outline": {
        "--border-color": theme.colors.red74Prx,
        "&:hover": {
          "--border-color": theme.colors.red74Prx
        }
      }
    }
  })
});

import { useMemo } from "react";
import { Select, Span } from "@/components";
import { useAppState } from "../../state";
import useDevices from "../../hooks/useDevices";

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeSinkId
  )?.label;

  const options = useMemo(
    () =>
      audioOutputDevices.map(({ deviceId, label }) => ({
        value: deviceId,
        label
      })),
    [audioOutputDevices]
  );

  return audioOutputDevices.length > 1 ? (
    <Select
      value={activeSinkId || ""}
      onIonChange={(e: CustomEvent) =>
        setActiveSinkId(e.detail.value as string)
      }
      options={options}
    >
      Audio Output
    </Select>
  ) : (
    <Span>{activeOutputLabel || "System Default Audio Output"}</Span>
  );
}

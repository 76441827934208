import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { GetWorkingDaysParams } from "@/core/types";
import { List } from "@/core/types/list";
import { Doctor, DoctorAdd, DoctorsQueryParams } from "./types";
import { axiosBaseQuery } from "../baseQuery";

export const doctorsApi = createApi({
  reducerPath: "Doctors",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Doctors", "Doctor", "WorkingHours"],
  endpoints: (build) => ({
    getDoctors: build.query<List<Doctor>, DoctorsQueryParams | void>({
      query: (params) => ({
        url: Api.doctors(),
        method: "get",
        params
      }),
      providesTags: () => {
        return [{ type: "Doctors" }];
      }
    }),
    getDoctor: build.query<Doctor, { id: number; full: boolean }>({
      query: ({ id, ...params }) => ({
        url: Api.doctor(id),
        method: "get",
        params
      }),
      providesTags: (_, __, { id }) => [{ type: "Doctor", id }],
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      }
    }),
    updateDoctor: build.mutation<void, Partial<Doctor> & { id: number }>({
      query: (doctor) => ({
        url: Api.doctor(doctor.id),
        method: "PUT",
        data: doctor
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Doctor", id },
        { type: "Doctors" }
      ]
    }),
    addDoctor: build.mutation<void, DoctorAdd>({
      query: (doctor) => ({
        url: Api.doctors(),
        method: "POST",
        data: doctor
      }),
      invalidatesTags: () => [{ type: "Doctors" }]
    }),
    deleteDoctor: build.mutation<void, number>({
      query: (id) => ({
        url: Api.user(id),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, id) => [
        { type: "Doctor", id },
        { type: "Doctors" }
      ]
    }),
    deleteDoctorAvatar: build.mutation<void, number>({
      query: (userId) => ({
        url: Api.userAvatar(userId),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, id) => [
        { type: "Doctor", id },
        { type: "Doctors" }
      ]
    }),
    getDoctorWorkingDays: build.query<
      {
        [key: string]: string[];
      },
      GetWorkingDaysParams
    >({
      query: ({ doctorId, ...params }) => ({
        url: Api.doctorWorkingDays(doctorId),
        method: "get",
        params
      }),
      providesTags: (_, __, { doctorId }) => [
        { type: "WorkingHours", id: doctorId }
      ]
    })
  })
});

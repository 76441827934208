import { useIonToast } from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
  closeCircleOutline,
  checkmarkCircleOutline,
  alertCircleOutline
} from "ionicons/icons";

type ToastOptions = {
  message?: string;
  duration?: number;
  position?: "bottom" | "top" | "middle";
};

type Toasts = {
  showError: (options: ToastOptions) => void;
  showSuccess: (options: ToastOptions) => void;
  showInfo: (options: ToastOptions) => void;
};

export const useToast = (): Toasts => {
  const [presentToast] = useIonToast();
  const { t } = useTranslation();

  const showError = ({
    message = "ERROR_GENERAL_ERROR",
    duration = 3000,
    position = "bottom"
  }: ToastOptions) => {
    presentToast({
      message: t(message),
      icon: closeCircleOutline,
      duration,
      position
    });
  };

  const showSuccess = ({
    message = "",
    duration = 3000,
    position = "bottom"
  }: ToastOptions) => {
    presentToast({
      message: t(message),
      icon: checkmarkCircleOutline,
      duration,
      position
    });
  };

  const showInfo = ({
    message = "",
    duration = 3000,
    position = "bottom"
  }: ToastOptions) => {
    presentToast({
      message: t(message),
      icon: alertCircleOutline,
      duration,
      position
    });
  };

  return { showError, showSuccess, showInfo };
};

/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { isPlainObject } from "@reduxjs/toolkit";

export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];

    if (typeof val !== "undefined") {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

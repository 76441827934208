import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from "react";
import { Participant, Room } from "twilio-video";

type SelectedParticipantContextType = [
  Participant | null,
  (participant: Participant) => void
];

export const SelectedParticipantContext =
  createContext<SelectedParticipantContextType>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(
    SelectedParticipantContext
  );
  return [selectedParticipant, setSelectedParticipant] as const;
}

type SelectedParticipantProviderProps = {
  room: Room | null;
  children: React.ReactNode;
};

export function SelectedParticipantProvider({
  room,
  children
}: SelectedParticipantProviderProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [selectedParticipant, _setSelectedParticipant] =
    useState<Participant | null>(null);
  const setSelectedParticipant = (participant: Participant) =>
    _setSelectedParticipant((prevParticipant) =>
      prevParticipant === participant ? null : participant
    );

  useEffect(() => {
    if (room) {
      const onDisconnect = () => _setSelectedParticipant(null);
      const handleParticipantDisconnected = (participant: Participant) =>
        _setSelectedParticipant((prevParticipant) =>
          prevParticipant === participant ? null : prevParticipant
        );

      room.on("disconnected", onDisconnect);
      room.on("participantDisconnected", handleParticipantDisconnected);

      return () => {
        room.off("disconnected", onDisconnect);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [room]);

  const contextValue = useMemo<SelectedParticipantContextType>(
    () => [selectedParticipant, setSelectedParticipant],
    [selectedParticipant, setSelectedParticipant]
  );

  return (
    <SelectedParticipantContext.Provider value={contextValue}>
      {children}
    </SelectedParticipantContext.Provider>
  );
}

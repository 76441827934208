import { useEffect } from "react";
import { getStyledElement } from "@/helpers";
import { Box } from "@/components";
import { useRouteQueryParams, useToast } from "@/hooks";
import { useAppState } from "../../state";
import useVideoContext from "../../hooks/useVideoContext";
import { useMediaErrorToast } from "../../hooks";
import useRoomState from "../../hooks/useRoomState";
import PreJoinScreen from "../../components/PreJoinScreen";
import Room from "../../components/Room";
import MenuBar from "../../components/MenuBar";

const Main = getStyledElement("main")({
  styles: ({ theme }) => ({
    overflow: "hidden",
    paddingBottom: theme.sizes.videoMenuHeight, // Leave some space for the footer
    background: "black",
    height: "100%"
  })
});

const Container = getStyledElement("div")({
  styles: ({ theme }) => ({
    background: theme.colors.black2BPrx,
    height: "100%",
    display: "flex",
    flexDirection: "column"
  })
});

export function RoomPage() {
  const roomState = useRoomState();
  const { user } = useAppState();
  const {
    getAudioAndVideoTracks,
    removeLocalVideoTrack,
    removeLocalAudioTrack
  } = useVideoContext();
  const { roomId } = useRouteQueryParams();
  const { showError } = useToast();

  const [mediaError, setMediaError] = useMediaErrorToast();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        setMediaError(error);
      });
    }
  }, [mediaError]);

  useEffect(() => {
    if (roomState === "reconnecting") {
      showError({
        message: "Conexiunea a fost pierdută: Reconectare...",
        position: "top",
        duration: 10000
      });
    }
  }, [roomState]);

  useEffect(() => {
    if (!roomId) {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    }
  }, [roomId]);

  return (
    <Container>
      {roomState === "disconnected" ? (
        <Box
          display="flex"
          justifyContent={["flex-start", "center"]}
          flexDirection="column"
          alignItems="center"
          flex="1"
        >
          <PreJoinScreen name={user?.displayName} roomName={roomId} />
        </Box>
      ) : (
        <Main>
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}

import { getStyledElement } from "@/helpers";
import { IonSelect } from "@ionic/react";

export const ButtonSelect = getStyledElement(IonSelect)({
  styles: ({ theme }) => ({
    backgroundColor: theme.colors.whitePrx,
    "--highlight-height": 0,
    "--highlight-color": "transparent",
    "--placeholder-color": theme.colors.primaryPrx,
    "--placeholder-opacity": 1,
    "--padding-start": "15px",
    "--padding-end": "15px",
    "min-height": "40px",
    overflow: "hidden",
    border: `1px solid ${theme.colors.primaryPrx}`,
    borderRadius: theme.sizes.buttonBorderRadius,
    "&::part(icon)": {
      opacity: 1,
      color: theme.colors.primaryPrx
    },
    "&::part(placeholder), &::part(text)": {
      color: theme.colors.primaryPrx
    },
    "&::part(container)": {
      flex: "1"
    },
    "&::part(label)": {
      color: theme.colors.primaryPrx
    },
    "--padding-bottom": "8px",
    "--padding-top": "8px",
    fontSize: "16px"
  })
});

import { getMediaStyles, getStyledElement } from "@/helpers";
import { IonCardSubtitle } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { Box } from "../box";

const CardSubtitle = getStyledElement(IonCardSubtitle)({
  styles: ({ theme }) => ({
    fontWeight: "600",
    whiteSpace: "nowrap",
    textTransform: "unset",
    color: theme.colors.darkPrx,
    ...getMediaStyles({
      whiteSpace: ["break-spaces", "nowrap"],
      fontSize: ["36px", "48px"]
    })
  })
});

export const PageDescription: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      height="100%"
      variant="flexCol"
      mt={["0", "50px"]}
      position="absolute"
      zIndex="1"
    >
      {/* <InputSearch /> */}
      <CardSubtitle>Consultatii medicale online</CardSubtitle>
      {children}
    </Box>
  );
};

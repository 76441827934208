import { getStyledElement } from "@/helpers";
import { FC, PropsWithChildren } from "react";
import { Box } from "../box";

const DropDown = getStyledElement(Box)({
  styles: ({ theme }) => ({
    position: "absolute",
    "z-index": "9",
    background: theme.colors.whitePrx,
    border: `1px solid ${theme.colors.red74Prx}`,
    "border-radius": "6px",
    padding: "5px",
    width: "max-content",
    "max-width": "200px",
    color: theme.colors.red74Prx,
    margin: "6px -20%",
    "font-size": "12px"
  })
});

const Arrow = getStyledElement(Box)({
  styles: ({ theme }) => ({
    "z-index": "10",
    "border-bottom": "solid 7px transparent",
    "border-top": `solid 7px ${theme.colors.red74Prx}`,
    "border-left": "solid 7px transparent",
    "border-right": "solid 7px transparent",
    position: "absolute",
    transform: "rotate(180deg)",
    bottom: "-7px",
    margin: "0 40%",
    "&:after": {
      top: "-9px",
      left: "-8px",
      content: "''",
      position: "absolute",
      display: "block",
      width: 0,
      height: 0,
      "border-bottom": "solid 8px transparent",
      "border-top": `solid 8px ${theme.colors.whitePrx}`,
      "border-left": "solid 8px transparent",
      "border-right": "solid 8px transparent"
    }
  })
});

export const ToastedError: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box variant="flexCol" alignItems="center">
      <Arrow />
      <DropDown>{children}</DropDown>
    </Box>
  );
};

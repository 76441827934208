import { Specialities } from "./specialities";

export const SPECIALITIES_IMAGES: { [key: string]: string } = {
  [Specialities.CARDIOLOG]: "assets/images/specialities/cardiolog.png",
  [Specialities.COSMETOLOG]: "assets/images/specialities/cosmetolog.png",
  [Specialities.DERMATOLOG]: "assets/images/specialities/dermatolog.png",
  [Specialities.DIETOLOG]:
    "assets/images/specialities/dietologNutritionist.png",
  [Specialities.GINECOLOG]: "assets/images/specialities/ginecolog.png",
  [Specialities.HEMATOLOG]: "assets/images/specialities/hematolog.png",
  [Specialities.HEPATOLOG]: "assets/images/specialities/hepatolog.png",
  [Specialities.INFECTIONIST]: "assets/images/specialities/infectionist.png",
  [Specialities.MEDIC_DE_FAMILIE]:
    "assets/images/specialities/medicDeFamilie.png",
  [Specialities.NEFROLOG]: "assets/images/specialities/nefrolog.png",
  [Specialities.NEUROLOG]: "assets/images/specialities/neurolog.png",
  [Specialities.ORTOPED]: "assets/images/specialities/ortopedTraumatolog.png",
  [Specialities.PEDIATRU]: "assets/images/specialities/pediatru.png",
  [Specialities.PSIHIATRU]: "assets/images/specialities/psihiatru.png",
  [Specialities.PSIHOLOG]: "assets/images/specialities/psiholog.png",
  [Specialities.PSIHOTERAPEUT]: "assets/images/specialities/psihoterapeut.png",
  [Specialities.PULMONOLOG]: "assets/images/specialities/pulmonolog.png",
  [Specialities.UROLOG]: "assets/images/specialities/urolog.png",
  [Specialities.VENEROLOG]: "assets/images/specialities/venerolog.png",
  [Specialities.ENDOCRINOLOG]: "assets/images/specialities/endocrinolog.png",
  [Specialities.GASTROLOG]: "assets/images/specialities/gastroenterolog.png",
  [Specialities.ORL]: "assets/images/specialities/orl.png",
  [Specialities.KINETOTERAPEUT]:
    "assets/images/specialities/kinetoterapeut.png",
  [Specialities.ALERGOLOG]: "assets/images/specialities/alergolog.png",
  [Specialities.REABILITOLOG]: "assets/images/specialities/reabilitolog.png",
  [Specialities.PROCTOLOG]: "assets/images/specialities/proctolog.png",
  [Specialities.TRIHOLOG]: "assets/images/specialities/triholog.png",
  [Specialities.STOMATOLOG]: "assets/images/specialities/stomatolog.png",

  [Specialities.ACUPUNCTURIST]: "assets/images/specialities/acupunctor.png",
  [Specialities.ANDROLOG]: "assets/images/specialities/androlog.png",
  [Specialities.CHIRURG]: "assets/images/specialities/chirurg.png",
  [Specialities.CHIRURG_ESTETICIAN]:
    "assets/images/specialities/chirurgEstetician.png",
  [Specialities.CHIRURG_PLASTICIAN]:
    "assets/images/specialities/chirurgPlastician.png",
  [Specialities.FITOTERAPEUT]: "assets/images/specialities/fitoterapeut.png",
  [Specialities.FIZIOTERAPEUT]: "assets/images/specialities/fizioterapeut.png",
  [Specialities.FLEBOLOG]: "assets/images/specialities/flebolog.png",
  [Specialities.IMAGIST]: "assets/images/specialities/imagist.png",
  [Specialities.INTERNIST_TERAPEUT]: "assets/images/specialities/internist.png",
  [Specialities.MAMOLOG]: "assets/images/specialities/mamolog.png",
  [Specialities.NEUROCHIRURG]: "assets/images/specialities/neurochirurg.png",
  [Specialities.OFTALMOLOG]: "assets/images/specialities/oftalmolog.png",
  [Specialities.ONCOLOG]: "assets/images/specialities/oncolog.png",
  [Specialities.PODOLOG]: "assets/images/specialities/podolog.png",
  [Specialities.REPRODUCTOLOG]: "assets/images/specialities/reproductolog.png",
  [Specialities.REUMATOLOG]: "assets/images/specialities/reumatolog.png",
  [Specialities.SEXOLOG]: "assets/images/specialities/sexolog.png",
  [Specialities.MASAJIST]: "assets/images/specialities/specialistInMasaj.png",
  [Specialities.TERAPEUT_MANUAL]:
    "assets/images/specialities/terapeutManual.png",
  [Specialities.ECOGRAFIST]: "assets/images/specialities/ecografist.png"
};

import { IonSelectOption } from "@ionic/react";
import React from "react";
import dayjs from "dayjs";
import { ButtonSelect } from "../buttonSelect";

type SelectTimePopoverProps = {
  onDateChange: (event: CustomEvent<{ value: string }>) => any;
  timeOptions: string[];
  isDisabled?: boolean;
  value?: string;
};

const SelectTimePopover: React.FC<SelectTimePopoverProps> = ({
  onDateChange,
  isDisabled,
  value,
  timeOptions = []
}) => {
  return (
    <ButtonSelect
      placeholder="--:--"
      label="Ora"
      mode="md"
      value={value}
      fontSize={["14px"]}
      disabled={isDisabled}
      onIonChange={onDateChange}
      okText="Selectează"
      cancelText="Anulează"
    >
      {timeOptions.map((option) => (
        <IonSelectOption value={option} key={option} color="primary">
          {dayjs(option).format("HH:mm A")}
        </IonSelectOption>
      ))}
    </ButtonSelect>
  );
};

export default React.memo(SelectTimePopover);

import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Schedule } from "@/core/types";
import { axiosBaseQuery } from "../baseQuery";
import { User } from "./types";

export const userApi = createApi({
  reducerPath: "User",
  baseQuery: axiosBaseQuery,
  tagTypes: ["User"],
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => ({
        url: Api.me(),
        method: "get"
      }),
      providesTags: () => [{ type: "User" }]
    }),
    checkPhone: build.mutation<string, string>({
      query: (code) => ({
        url: Api.checkVerificatinPhone(),
        method: "POST",
        data: { code }
      }),
      invalidatesTags: () => [{ type: "User" }]
    }),
    updateMe: build.mutation<void, Partial<User>>({
      query: (user) => ({
        url: Api.me(),
        method: "PUT",
        data: user
      }),
      invalidatesTags: () => [{ type: "User" }]
    }),
    updateSchedule: build.mutation<void, Schedule>({
      query: (schedule) => ({
        url: Api.meSchedule(),
        method: "PUT",
        data: schedule
      }),
      invalidatesTags: () => [{ type: "User" }]
    })
  })
});

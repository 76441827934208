import { useContext } from "react";
import { VideoContext } from "../../contexts/videoContext";

export default function useVideoContext() {
  const context = useContext(VideoContext);

  if (!context) {
    throw new Error("useVideoContext must be used within a VideoProvider");
  }

  return context;
}

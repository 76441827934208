import React from "react";
import { Participant as IParticipant } from "twilio-video";
import ParticipantInfo from "../ParticipantInfo";
import ParticipantTracks from "../ParticipantTracks";

interface ParticipantProps {
  participant: IParticipant;
  onClick?: () => void;
  isLocalParticipant?: boolean;
}

function Participant({
  participant,
  onClick,
  isLocalParticipant
}: ParticipantProps) {
  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isLocalParticipant={isLocalParticipant}
    >
      <ParticipantTracks
        participant={participant}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default React.memo(Participant);

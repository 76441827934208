import { useCallback, useEffect, useState } from "react";
import { AppStorage } from "@/services/storage";
import { SELECTED_AUDIO_OUTPUT_KEY } from "../../constants";
import useDevices from "../useDevices";

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [activeSinkId, _setActiveSinkId] = useState("default");

  const setActiveSinkId = useCallback((sinkId: string) => {
    AppStorage.set(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
    _setActiveSinkId(sinkId);
  }, []);

  useEffect(() => {
    const setLocalState = async () => {
      const selectedSinkId = await AppStorage.get(SELECTED_AUDIO_OUTPUT_KEY);
      const hasSelectedAudioOutputDevice = audioOutputDevices.some(
        (device) => selectedSinkId && device.deviceId === selectedSinkId
      );

      if (hasSelectedAudioOutputDevice) {
        _setActiveSinkId(selectedSinkId!);
      }
    };

    setLocalState();
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId] as const;
}

import { PropsWithChildren, useRef } from "react";
import { TwilioError } from "twilio-video";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { ButtonListItem, P } from "@/components";

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | Error | null;
}

function enhanceMessage(message = "", code?: number) {
  switch (true) {
    case code === 20101: // Invalid token error
      return `${message}. Please make sure you are using the correct credentials.`;
    case message === "Permission denied by system":
      return "Unable to share your screen. Please make sure that your operating system has the correct permissions enabled for screen sharing.";
    default:
      return message;
  }
}

function ErrorDialog({
  dismissError,
  error
}: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal ref={modal} isOpen={!!error} onDidDismiss={() => dismissError()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ERROR</IonTitle>
          <IonButtons slot="end">
            <ButtonListItem
              fontSize="18px !important"
              color="primary"
              onClick={() => {
                modal.current?.dismiss();
              }}
            >
              OK
            </ButtonListItem>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <P>{enhancedMessage}</P>
        {Boolean(code) && (
          <pre>
            <code>Error Code: {code}</code>
          </pre>
        )}
      </IonContent>
    </IonModal>
  );
}

export default ErrorDialog;

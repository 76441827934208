/* eslint-disable func-names */
import { Countries } from "@/components/input/countries";
import { TFunction } from "i18next";
import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    validatePhone(message?: string): StringSchema<TType, TContext>;
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validatePhone",
  function (message: string) {
    return this.test("test-phone", message, function (value = "") {
      const { path, createError } = this;
      const country = Countries.find(({ prefix }) => value?.startsWith(prefix));

      if (!country) return createError({ path });

      const maskDigitsNr = (country.mask.match(/x/g) || []).length;
      const valueDigitsNr = value.replace(country.prefix, "").length;

      if (maskDigitsNr !== valueDigitsNr) return createError({ path });

      return true;
    });
  }
);

const setYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      default: t("ERRORS.FORM.FIELD_INVALID"),
      required: t("ERRORS.FORM.REQUIRED"),
      validatePhone: t("ERRORS.FORM.INVALID_PHONE")
    } as any,
    string: {
      min: ({ min }) => t("ERRORS.FORM.MIN_LENGTH", { min }),
      max: ({ max }) => t("ERRORS.FORM.MAX_LENGTH", { max }),
      email: t("ERRORS.FORM.EMAIL_INVALID")
    }
  });
};

export { yup, setYupLocale };

import { FC, PropsWithChildren, useMemo } from "react";
import useSpeakerViewParticipants from "../../hooks/useSpeakerViewParticipants";
import { ParticipantContext } from "../../contexts/participantContext";

export const ParticipantProvider: FC<PropsWithChildren> = ({ children }) => {
  const speakerViewParticipants = useSpeakerViewParticipants();

  const participantContextValue = useMemo(
    () => ({
      speakerViewParticipants
    }),
    [speakerViewParticipants]
  );

  return (
    <ParticipantContext.Provider value={participantContextValue}>
      {children}
    </ParticipantContext.Provider>
  );
};

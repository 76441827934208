import { useMediaQuery } from "react-responsive";
import { useAppTheme } from "./useAppTheme";

export const useMedia = (): {
  isDesktop: boolean;
  isMobile: boolean;
  xl: boolean;
  lg: boolean;
} => {
  const theme = useAppTheme();

  return {
    isDesktop: useMediaQuery({
      query: `(min-width: ${theme.breakpoints.xl})`
    }),
    isMobile: useMediaQuery({ query: `(max-width: ${theme.breakpoints.sm})` }),
    xl: useMediaQuery({
      query: `(min-width: ${theme.breakpoints.xl})`
    }),
    lg: useMediaQuery({
      query: `(min-width: ${theme.breakpoints.lg})`
    })
  };
};

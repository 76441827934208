import { Country } from "./types";

export const Countries: Country[] = [
  {
    name: "Afghanistan",
    code: "AF",
    prefix: "+93",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Albania",
    code: "AL",
    prefix: "+355",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Algeria",
    code: "DZ",
    prefix: "+213",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Andorra",
    code: "AD",
    prefix: "+376",
    prefixSeparator: " ",
    mask: "xxx-xxx"
  },
  {
    name: "Angola",
    code: "AO",
    prefix: "+244",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Antarctica",
    code: "AQ",
    prefix: "+672",
    prefixSeparator: " ",
    mask: "xx-xxx"
  },
  {
    name: "Argentina",
    code: "AR",
    prefix: "+54",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Armenia",
    code: "AM",
    prefix: "+374",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Aruba",
    code: "AW",
    prefix: "+297",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Australia",
    code: "AU",
    prefix: "+61",
    prefixSeparator: " ",
    mask: "x-xxxx-xxxx"
  },
  {
    name: "Austria",
    code: "AT",
    prefix: "+43",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    prefix: "+994",
    prefixSeparator: " ",
    mask: "xx-xxx-xx-xx"
  },
  {
    name: "Bahrain",
    code: "BH",
    prefix: "+973",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Bangladesh",
    code: "BD",
    prefix: "+880",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Belarus",
    code: "BY",
    prefix: "+375",
    prefixSeparator: " ",
    mask: "xx-xxx-xx-xx"
  },
  {
    name: "Belgium",
    code: "BE",
    prefix: "+32",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Belize",
    code: "BZ",
    prefix: "+501",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Benin",
    code: "BJ",
    prefix: "+229",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Bhutan",
    code: "BT",
    prefix: "+975",
    prefixSeparator: " ",
    mask: "x-xxx-xxx"
  },
  {
    name: "Bolivia",
    code: "BO",
    prefix: "+591",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    prefix: "+387",
    prefixSeparator: " ",
    mask: "xx-xxxx"
  },
  {
    name: "Botswana",
    code: "BW",
    prefix: "+267",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Brazil",
    code: "BR",
    prefix: "+55",
    prefixSeparator: " ",
    mask: "xx-xxxx-xxxx"
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    prefix: "+246",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Brunei",
    code: "BN",
    prefix: "+673",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Bulgaria",
    code: "BG",
    prefix: "+359",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Burkina Faso",
    code: "BF",
    prefix: "+226",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Burundi",
    code: "BI",
    prefix: "+257",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Cambodia",
    code: "KH",
    prefix: "+855",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Cameroon",
    code: "CM",
    prefix: "+237",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Cape Verde",
    code: "CV",
    prefix: "+238",
    prefixSeparator: " ",
    mask: "xxx-xx-xx"
  },
  {
    name: "Central African Republic",
    code: "CF",
    prefix: "+236",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Chad",
    code: "TD",
    prefix: "+235",
    prefixSeparator: " ",
    mask: "xx-xx-xx-xx"
  },
  {
    name: "Chile",
    code: "CL",
    prefix: "+56",
    prefixSeparator: " ",
    mask: "x-xxxx-xxxx"
  },
  {
    name: "China",
    code: "CN",
    prefix: "+86",
    prefixSeparator: " ",
    mask: "xx-xxxxx-xxxxx"
  },
  {
    name: "Colombia",
    code: "CO",
    prefix: "+57",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Comoros",
    code: "KM",
    prefix: "+269",
    prefixSeparator: " ",
    mask: "xx-xxxxx"
  },
  {
    name: "Cook Islands",
    code: "CK",
    prefix: "+682",
    prefixSeparator: " ",
    mask: "xx-xxx"
  },
  {
    name: "Costa Rica",
    code: "CR",
    prefix: "+506",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Croatia",
    code: "HR",
    prefix: "+385",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Cuba",
    code: "CU",
    prefix: "+53",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Curacao",
    code: "CW",
    prefix: "+599",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Cyprus",
    code: "CY",
    prefix: "+357",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Czech Republic",
    code: "CZ",
    prefix: "+420",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    prefix: "+243",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Denmark",
    code: "DK",
    prefix: "+45",
    prefixSeparator: " ",
    mask: "xx-xx-xx-xx"
  },
  {
    name: "Djibouti",
    code: "DJ",
    prefix: "+253",
    prefixSeparator: " ",
    mask: "xx-xx-xx-xx"
  },
  {
    name: "East Timor",
    code: "TL",
    prefix: "+670",
    prefixSeparator: " ",
    mask: "x-xxxxx"
  },
  {
    name: "Ecuador",
    code: "EC",
    prefix: "+593",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Egypt",
    code: "EG",
    prefix: "+20",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "El Salvador",
    code: "SV",
    prefix: "+503",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    prefix: "+240",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Eritrea",
    code: "ER",
    prefix: "+291",
    prefixSeparator: " ",
    mask: "x-xxx-xxx"
  },
  {
    name: "Estonia",
    code: "EE",
    prefix: "+372",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Ethiopia",
    code: "ET",
    prefix: "+251",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Falkland Islands",
    code: "FK",
    prefix: "+500",
    prefixSeparator: " ",
    mask: "xxxxx"
  },
  {
    name: "Faroe Islands",
    code: "FO",
    prefix: "+298",
    prefixSeparator: " ",
    mask: "xxx-xxx"
  },
  {
    name: "Fiji",
    code: "FJ",
    prefix: "+679",
    prefixSeparator: " ",
    mask: "xx-xxxxx"
  },
  {
    name: "Finland",
    code: "FI",
    prefix: "+358",
    prefixSeparator: " ",
    mask: "xxx-xxx-xx-xx"
  },
  {
    name: "France",
    code: "FR",
    prefix: "+33",
    prefixSeparator: "-",
    mask: "x-xx-xx-xx-xx"
  },
  {
    name: "French Polynesia",
    code: "PF",
    prefix: "+689",
    prefixSeparator: " ",
    mask: "xx-xx-xx"
  },
  {
    name: "Gabon",
    code: "GA",
    prefix: "+241",
    prefixSeparator: " ",
    mask: "x-xx-xx-xx"
  },
  {
    name: "Gambia",
    code: "GM",
    prefix: "+220",
    prefixSeparator: " ",
    mask: "xxx-xx-xx"
  },
  {
    name: "Georgia",
    code: "GE",
    prefix: "+995",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Germany",
    code: "DE",
    prefix: "+49",
    prefixSeparator: " ",
    mask: "xxxx-xxx-xxxx"
  },
  {
    name: "Ghana",
    code: "GH",
    prefix: "+233",
    prefixSeparator: " ",
    mask: "###-###"
  },
  {
    name: "Gibraltar",
    code: "GI",
    prefix: "+350",
    prefixSeparator: " ",
    mask: "xxx-xxxxx"
  },
  {
    name: "Greece",
    code: "GR",
    prefix: "+30",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Greenland",
    code: "GL",
    prefix: "+299",
    prefixSeparator: " ",
    mask: "xx-xx-xx"
  },
  {
    name: "Guatemala",
    code: "GT",
    prefix: "+502",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Guinea",
    code: "GN",
    prefix: "+224",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    prefix: "+245",
    prefixSeparator: " ",
    mask: "x-xxxxxx"
  },
  {
    name: "Guyana",
    code: "GY",
    prefix: "+592",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Haiti",
    code: "HT",
    prefix: "+509",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Honduras",
    code: "HN",
    prefix: "+504",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Hong Kong",
    code: "HK",
    prefix: "+852",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Hungary",
    code: "HU",
    prefix: "+36",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Iceland",
    code: "IS",
    prefix: "+354",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "India",
    code: "IN",
    prefix: "+91",
    prefixSeparator: " ",
    mask: "xxxx-xxx-xxx"
  },
  {
    name: "Indonesia",
    code: "ID",
    prefix: "+62",
    prefixSeparator: " ",
    mask: "xx-xxx-xx-xxx"
  },
  {
    name: "Iran",
    code: "IR",
    prefix: "+98",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Iraq",
    code: "IQ",
    prefix: "+964",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Ireland",
    code: "IE",
    prefix: "+353",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Israel",
    code: "IL",
    prefix: "+972",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Italy",
    code: "IT",
    prefix: "+39",
    prefixSeparator: " ",
    mask: "xxx-xxxx-xxx"
  },
  {
    name: "Ivory Coast",
    code: "CI",
    prefix: "+225",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Japan",
    code: "JP",
    prefix: "+81",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Jordan",
    code: "JO",
    prefix: "+962",
    prefixSeparator: " ",
    mask: "x-xxxx-xxxx"
  },
  {
    name: "Kenya",
    code: "KE",
    prefix: "+254",
    prefixSeparator: " ",
    mask: "xxx-xxxxxx"
  },
  {
    name: "Kiribati",
    code: "KI",
    prefix: "+686",
    prefixSeparator: " ",
    mask: "xx-xxx"
  },
  {
    name: "Kuwait",
    code: "KW",
    prefix: "+965",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    prefix: "+996",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Laos",
    code: "LA",
    prefix: "+856",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Latvia",
    code: "LV",
    prefix: "+371",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Lebanon",
    code: "LB",
    prefix: "+961",
    prefixSeparator: " ",
    mask: "x-xxx-xxx"
  },
  {
    name: "Lesotho",
    code: "LS",
    prefix: "+266",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Liberia",
    code: "LR",
    prefix: "+231",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Libya",
    code: "LY",
    prefix: "+218",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Liechtenstein",
    code: "LI",
    prefix: "+423",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Lithuania",
    code: "LT",
    prefix: "+370",
    prefixSeparator: " ",
    mask: "xxx-xx-xxx"
  },
  {
    name: "Luxembourg",
    code: "LU",
    prefix: "+352",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Macau",
    code: "MO",
    prefix: "+853",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Macedonia",
    code: "MK",
    prefix: "+389",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Madagascar",
    code: "MG",
    prefix: "+261",
    prefixSeparator: " ",
    mask: "xx-xx-xxxxx"
  },
  {
    name: "Malawi",
    code: "MW",
    prefix: "+265",
    prefixSeparator: " ",
    mask: "xxx-xxx"
  },
  {
    name: "Malaysia",
    code: "MY",
    prefix: "+60",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Maldives",
    code: "MV",
    prefix: "+960",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Mali",
    code: "ML",
    prefix: "+223",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Malta",
    code: "MT",
    prefix: "+356",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Marshall Islands",
    code: "MH",
    prefix: "+692",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Mauritania",
    code: "MR",
    prefix: "+222",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Mauritius",
    code: "MU",
    prefix: "+230",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Mexico",
    code: "MX",
    prefix: "+52",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Micronesia",
    code: "FM",
    prefix: "+691",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Moldova",
    code: "MD",
    prefix: "+373",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Monaco",
    code: "MC",
    prefix: "+377",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Mongolia",
    code: "MN",
    prefix: "+976",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Montenegro",
    code: "ME",
    prefix: "+382",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Morocco",
    code: "MA",
    prefix: "+212",
    prefixSeparator: " ",
    mask: "xx-xxxx-xxx"
  },
  {
    name: "Mozambique",
    code: "MZ",
    prefix: "+258",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Myanmar",
    code: "MM",
    prefix: "+95",
    prefixSeparator: " ",
    mask: "xxx-xxx"
  },
  {
    name: "Namibia",
    code: "NA",
    prefix: "+264",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Nauru",
    code: "NR",
    prefix: "+674",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Nepal",
    code: "NP",
    prefix: "+977",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Netherlands",
    code: "NL",
    prefix: "+31",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "New Caledonia",
    code: "NC",
    prefix: "+687",
    prefixSeparator: " ",
    mask: "xx-xxxx"
  },
  {
    name: "New Zealand",
    code: "NZ",
    prefix: "+64",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Nicaragua",
    code: "NI",
    prefix: "+505",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Niger",
    code: "NE",
    prefix: "+227",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Nigeria",
    code: "NG",
    prefix: "+234",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Niue",
    code: "NU",
    prefix: "+683",
    prefixSeparator: " ",
    mask: "xxxx"
  },
  {
    name: "North Korea",
    code: "KP",
    prefix: "+850",
    prefixSeparator: " ",
    mask: "xxxx-xxxxxxxxxxxxx"
  },
  {
    name: "Norway",
    code: "NO",
    prefix: "+47",
    prefixSeparator: " ",
    mask: "xxx-xx-xxx"
  },
  {
    name: "Oman",
    code: "OM",
    prefix: "+968",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Pakistan",
    code: "PK",
    prefix: "+92",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Palau",
    code: "PW",
    prefix: "+680",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Palestine",
    code: "PS",
    prefix: "+970",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Panama",
    code: "PA",
    prefix: "+507",
    prefixSeparator: " ",
    mask: "xxx-xxxx"
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    prefix: "+675",
    prefixSeparator: " ",
    mask: "xxx-xx-xxx"
  },
  {
    name: "Paraguay",
    code: "PY",
    prefix: "+595",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Peru",
    code: "PE",
    prefix: "+51",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Philippines",
    code: "PH",
    prefix: "+63",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Poland",
    code: "PL",
    prefix: "+48",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Portugal",
    code: "PT",
    prefix: "+351",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Qatar",
    code: "QA",
    prefix: "+974",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Republic of the Congo",
    code: "CG",
    prefix: "+242",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Romania",
    code: "RO",
    prefix: "+40",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Russia",
    code: "RU",
    prefix: "+7",
    prefixSeparator: " ",
    mask: "xxx-xxx-xx-xx"
  },
  {
    name: "Rwanda",
    code: "RW",
    prefix: "+250",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Saint Helena",
    code: "SH",
    prefix: "+290",
    prefixSeparator: " ",
    mask: "xxxx"
  },
  {
    name: "Samoa",
    code: "WS",
    prefix: "+685",
    prefixSeparator: " ",
    mask: "xx-xxxx"
  },
  {
    name: "San Marino",
    code: "SM",
    prefix: "+378",
    prefixSeparator: " ",
    mask: "xxxx-xxxxxx"
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    prefix: "+239",
    prefixSeparator: " ",
    mask: "xx-xxxxx"
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    prefix: "+966",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Senegal",
    code: "SN",
    prefix: "+221",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Serbia",
    code: "RS",
    prefix: "+381",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Seychelles",
    code: "SC",
    prefix: "+248",
    prefixSeparator: " ",
    mask: "x-xxx-xxx"
  },
  {
    name: "Sierra Leone",
    code: "SL",
    prefix: "+232",
    prefixSeparator: " ",
    mask: "xx-xxxxxx"
  },
  {
    name: "Singapore",
    code: "SG",
    prefix: "+65",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Slovakia",
    code: "SK",
    prefix: "+421",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Slovenia",
    code: "SI",
    prefix: "+386",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Solomon Islands",
    code: "SB",
    prefix: "+677",
    prefixSeparator: " ",
    mask: "xxxxx"
  },
  {
    name: "Somalia",
    code: "SO",
    prefix: "+252",
    prefixSeparator: " ",
    mask: "x-xxx-xxx"
  },
  {
    name: "South Africa",
    code: "ZA",
    prefix: "+27",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "South Korea",
    code: "KR",
    prefix: "+82",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "South Sudan",
    code: "SS",
    prefix: "+211",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Spain",
    code: "ES",
    prefix: "+34",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Sri Lanka",
    code: "LK",
    prefix: "+94",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Sudan",
    code: "SD",
    prefix: "+249",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Suriname",
    code: "SR",
    prefix: "+597",
    prefixSeparator: " ",
    mask: "xxx-xxx"
  },
  {
    name: "Swaziland",
    code: "SZ",
    prefix: "+268",
    prefixSeparator: " ",
    mask: "xx-xx-xxxx"
  },
  {
    name: "Sweden",
    code: "SE",
    prefix: "+46",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Switzerland",
    code: "CH",
    prefix: "+41",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Syria",
    code: "SY",
    prefix: "+963",
    prefixSeparator: " ",
    mask: "xx-xxxx-xxx"
  },
  {
    name: "Taiwan",
    code: "TW",
    prefix: "+886",
    prefixSeparator: " ",
    mask: "xxxx-xxxx"
  },
  {
    name: "Tajikistan",
    code: "TJ",
    prefix: "+992",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Tanzania",
    code: "TZ",
    prefix: "+255",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Thailand",
    code: "TH",
    prefix: "+66",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Togo",
    code: "TG",
    prefix: "+228",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Tokelau",
    code: "TK",
    prefix: "+690",
    prefixSeparator: " ",
    mask: "xxxx"
  },
  {
    name: "Tonga",
    code: "TO",
    prefix: "+676",
    prefixSeparator: " ",
    mask: "xxxxx"
  },
  {
    name: "Tunisia",
    code: "TN",
    prefix: "+216",
    prefixSeparator: " ",
    mask: "xx-xxx-xxx"
  },
  {
    name: "Turkey",
    code: "TR",
    prefix: "+90",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Turkmenistan",
    code: "TM",
    prefix: "+993",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "Tuvalu",
    code: "TV",
    prefix: "+688",
    prefixSeparator: " ",
    mask: "xxxx"
  },
  {
    name: "Uganda",
    code: "UG",
    prefix: "+256",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Ukraine",
    code: "UA",
    prefix: "+380",
    prefixSeparator: " ",
    mask: "xx-xxx-xx-xx"
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    prefix: "+971",
    prefixSeparator: " ",
    mask: "x-xxx-xxxx"
  },
  {
    name: "United States",
    code: "US",
    prefix: "+1",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "United Kingdom",
    code: "GB",
    prefix: "+44",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Uruguay",
    code: "UY",
    prefix: "+598",
    prefixSeparator: " ",
    mask: "x-xxx-xx-xx"
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    prefix: "+998",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Vanuatu",
    code: "VU",
    prefix: "+678",
    prefixSeparator: " ",
    mask: "xx-xxxxx"
  },
  {
    name: "Vatican",
    code: "VA",
    prefix: "+379",
    prefixSeparator: " ",
    mask: "xxxxx"
  },
  {
    name: "Venezuela",
    code: "VE",
    prefix: "+58",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxxx"
  },
  {
    name: "Vietnam",
    code: "VN",
    prefix: "+84",
    prefixSeparator: " ",
    mask: "xxx-xxxx-xxx"
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    prefix: "+681",
    prefixSeparator: " ",
    mask: "xx-xxxx"
  },
  {
    name: "Yemen",
    code: "YE",
    prefix: "+967",
    prefixSeparator: " ",
    mask: "xxx-xxx-xxx"
  },
  {
    name: "Zambia",
    code: "ZM",
    prefix: "+260",
    prefixSeparator: " ",
    mask: "xx-xxx-xxxx"
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    prefix: "+263",
    prefixSeparator: " ",
    mask: "x-xxxxxx"
  }
];

import { IonFab, IonFabButton, IonFabList } from "@ionic/react";
import { chevronBackCircle, createOutline } from "ionicons/icons";
import { getStyledElement } from "@/helpers";
import { Icon } from "../icon";

const FabButton = getStyledElement(IonFabButton)({
  styles: () => ({
    width: "40px",
    height: "40px",
    "--box-shadow": "none"
  })
});

const FabButtonItem = getStyledElement(FabButton)({
  styles: ({ theme }) => ({
    width: "40px",
    height: "40px",
    "--box-shadow": "none",
    border: `2px solid ${theme.colors.primaryPrx}`,
    borderRadius: "50%"
  })
});

const Fab = getStyledElement(IonFab)({});

const FabList = getStyledElement(IonFabList)({
  styles: () => ({
    minWidth: "40px",
    minHeight: "40px"
  })
});

export const ActionButton = () => {
  return (
    <Fab>
      <FabButton>
        <Icon icon={chevronBackCircle} />
      </FabButton>
      <FabList side="start">
        <FabButtonItem>
          <Icon color="primary" icon={createOutline} />
        </FabButtonItem>
      </FabList>
    </Fab>
  );
};

export const Api = {
  refresh() {
    return "/auth/refresh";
  },
  logout() {
    return "/auth/logout";
  },
  login() {
    return "/auth/login";
  },
  registration() {
    return "/auth/registration";
  },
  changePassword() {
    return "/auth/change-password";
  },
  me() {
    return "/auth/me";
  },
  meAvatar() {
    return "/auth/me/avatar";
  },
  meSchedule() {
    return `${this.me()}/schedule`;
  },
  initVerificationPhone() {
    return "/auth/init-verification-phone";
  },
  checkVerificatinPhone() {
    return "/auth/check-verification-code";
  },
  meAppointments() {
    return `${this.me()}/appointments`;
  },
  prescriptions(userId: number) {
    return `${this.user(userId)}/prescriptions`;
  },
  prescription(userId: number, id: number) {
    return `${this.user(userId)}/prescriptions/${id}`;
  },
  appointments(userId: number) {
    return `${this.user(userId)}/appointments`;
  },
  appointment(userId: number, id: number) {
    return `${this.appointments(userId)}/${id}`;
  },
  getUserAppointment(userId: number, id: number) {
    return `${this.appointments(userId)}/${id}`;
  },
  appointmentUseMedicalInsurance(userId: number, id: number) {
    return `${this.getUserAppointment(userId, id)}/use-insurance`;
  },
  doctors() {
    return "/doctors";
  },
  medicalInsuranceReport() {
    return "/reports/medical-insurance";
  },
  specialties() {
    return "/specialties";
  },
  users() {
    return "/users";
  },
  user(id: number) {
    return `${this.users()}/${id}`;
  },
  userPassport(id: number) {
    return `${this.user(id)}/passport`;
  },
  medicalInsurances(userId: number) {
    return `${this.user(userId)}/medical-insurance`;
  },
  medicalInsurance(userId: number, id: number) {
    return `${this.medicalInsurances(userId)}/${id}`;
  },
  medicalInsuranceFiles(userId: number, id: number) {
    return `${this.medicalInsurance(userId, id)}/files`;
  },
  medicalInsurancePhoto(id: number) {
    return `${this.user(id)}/medical-insurance/user-photos`;
  },
  file(id: number) {
    return `${this.files()}/${id}`;
  },
  files() {
    return "/files";
  },
  userAvatar(id: number) {
    return `${this.user(id)}/avatar`;
  },
  avatar(id: number) {
    return `/avatar/${id}`;
  },
  doctor(id: number) {
    return `${this.doctors()}/${id}`;
  },
  pacients() {
    return "/pacients";
  },
  pacient(id: number) {
    return `${this.pacients()}/${id}`;
  },
  doctorWorkingDays(id: number) {
    return `${this.doctor(id)}/working-days`;
  },
  doctorWorkingHours(id: number) {
    return `${this.doctor(id)}/working-hours`;
  },
  appointmentFinish(userId: number, id: number) {
    return `${this.appointments(userId)}/${id}/finish`;
  },
  payment() {
    return "/payment";
  },
  paymentRefund() {
    return `${this.payment()}/refund`;
  },
  paymentStatus() {
    return `${this.payment()}/status`;
  },
  paymentCommit(id: number) {
    return `${this.payment()}/${id}/commit`;
  },
  videoCredentials() {
    return "/video";
  },
  finishPayment() {
    return `${this.payment()}/finish`;
  }
};

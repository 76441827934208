import { useRef } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { ButtonListItem, Col, Grid, H2, Row } from "@/components";
import AudioInputList from "../AudioInputList";
import AudioOutputList from "../AudioOutputList";
import VideoInputList from "../VideoInputList";

export default function DeviceSelectionDialog({
  setIsOpen,
  isOpen
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal ref={modal} isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Setări video și audio</IonTitle>
          <IonButtons slot="end">
            <ButtonListItem
              fontSize="18px !important"
              color="primary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Închide
            </ButtonListItem>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Grid px="20px">
          <H2 fontSize="20px">Video</H2>
          <Row>
            <Col p="0">
              <VideoInputList />
            </Col>
          </Row>

          <H2 fontSize="20px">Audio</H2>

          <Row>
            <Col px="0">
              <AudioInputList />
            </Col>
          </Row>

          <Row>
            <Col px="0">
              <AudioOutputList />
            </Col>
          </Row>
        </Grid>
      </IonContent>
    </IonModal>
  );
}

export enum AppointmentStatus {
  WaitingForPayment = "WAITING_FOR_PAYMENT",
  PaymentInProgress = "PAYMENT_IN_PROGRESS",
  Reserved = "RESERVED",
  Paid = "PAID",
  Done = "DONE",
  Repeat = "REPEAT",
  Canceled = "CANCELED"
}

export const APPOINTMENT_STATUS_LABELS = {
  [AppointmentStatus.WaitingForPayment]:
    "APPOINTMENTS_STATUSES.WAITING_FOR_PAYMENT",
  [AppointmentStatus.PaymentInProgress]:
    "APPOINTMENTS_STATUSES.PAYMENT_IN_PROGRESS",
  [AppointmentStatus.Reserved]: "APPOINTMENTS_STATUSES.RESERVED",
  [AppointmentStatus.Paid]: "APPOINTMENTS_STATUSES.PAID",
  [AppointmentStatus.Done]: "APPOINTMENTS_STATUSES.DONE",
  [AppointmentStatus.Repeat]: "APPOINTMENTS_STATUSES.REPEAT",
  [AppointmentStatus.Canceled]: "APPOINTMENTS_STATUSES.CANCELED"
};

export const APPOINTMENT_STATUSES = {
  [AppointmentStatus.Canceled]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.Canceled],
    color: "#F26674"
  },
  [AppointmentStatus.WaitingForPayment]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.WaitingForPayment],
    color: "#fcba03"
  },
  [AppointmentStatus.PaymentInProgress]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.PaymentInProgress],
    color: "#fcba03"
  },
  [AppointmentStatus.Reserved]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.Reserved],
    color: "#2997E7"
  },
  [AppointmentStatus.Paid]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.Paid],
    color: "#45BF93"
  },
  [AppointmentStatus.Done]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.Done],
    color: "#45BF93"
  },
  [AppointmentStatus.Repeat]: {
    message: APPOINTMENT_STATUS_LABELS[AppointmentStatus.Repeat],
    color: "#2997E7"
  }
};

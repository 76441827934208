import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EN from "./locales/en/common.json";
import RO from "./locales/ro/common.json";
import RU from "./locales/ru/common.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: EN
    },
    ro: {
      common: RO
    },
    ru: {
      common: RU
    }
  },
  preload: ["ro"],
  defaultNS: "common",
  lng: "ro",
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ["path"],
    lookupFromPathIndex: 0,
    checkWhitelist: true
  }
});

export default i18n;

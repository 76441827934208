import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../baseQuery";

export const specialtiesApi = createApi({
  reducerPath: "Specialties",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Specialties"],
  endpoints: (build) => ({
    getSpecialties: build.query<string[], void>({
      query: () => ({
        url: Api.specialties(),
        method: "get"
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map((specialty) => ({
                type: "Specialties" as const,
                specialty
              })),
              { type: "Specialties", id: "LIST" }
            ]
          : [{ type: "Specialties", id: "LIST" }];
      }
    })
  })
});

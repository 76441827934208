import { useToast } from "@/hooks";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useDevices from "./useDevices";
import useVideoContext from "./useVideoContext";

export function getMediaError(
  hasAudio: boolean,
  hasVideo: boolean,
  error?: Error
): string {
  let message = "";

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === "CameraPermissionsDenied":
      message =
        "Unable to Access Media! The user has denied permission to use video. Please grant permission to the browser to access the camera.";
      break;
    case error?.message === "MicrophonePermissionsDenied":
      message =
        "Unable to Access Media: The user has denied permission to use audio. Please grant permission to the browser to access the microphone.";
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === "NotAllowedError":
      if (error!.message === "Permission denied by system") {
        // Chrome only
        message =
          "Unable to Access Media: The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.";
      } else {
        message =
          "Unable to Access Media: The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.";
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === "NotFoundError":
      message =
        "Cannot Find Microphone or Camera: The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.";
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      message = `Error Acquiring Media ${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      message =
        "No Camera or Microphone Detected: Other participants in the room will be unable to see and hear you.";
      break;

    case !hasVideo:
      message =
        "No Camera Detected: Other participants in the room will be unable to see you.";
      break;

    case !hasAudio:
      message =
        "No Microphone Detected: Other participants in the room will be unable to hear you.";
      break;

    default:
      message = "General Error";
  }

  return message;
}

export const useMediaErrorToast = (): [
  Error | undefined,
  Dispatch<SetStateAction<Error | undefined>>
] => {
  const { showInfo } = useToast();
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();
  const [mediaError, setMediaError] = useState<Error>();

  const { isAcquiringLocalTracks } = useVideoContext();

  useEffect(() => {
    const isShowError =
      !isAcquiringLocalTracks &&
      (Boolean(mediaError) || !hasAudioInputDevices || !hasVideoInputDevices);

    if (!isShowError) {
      return;
    }

    const message = getMediaError(
      hasAudioInputDevices,
      hasVideoInputDevices,
      mediaError
    );
    showInfo({ message });
  }, [
    mediaError,
    hasAudioInputDevices,
    hasVideoInputDevices,
    isAcquiringLocalTracks
  ]);

  return [mediaError, setMediaError];
};

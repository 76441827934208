import { getStyledElement } from "@/helpers";
import { IonIcon } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import { FC, PropsWithChildren } from "react";
import { useAppTheme } from "@/hooks";
import { P } from "../p";

const WarningIcon = getStyledElement(IonIcon)({
  styles: () => ({
    "min-width": "25px",
    "margin-right": "5px"
  })
});

export const WarningInfo: FC<PropsWithChildren> = ({ children }) => {
  const theme = useAppTheme();

  return (
    <P m="0" display="flex" alignItems="center" color={theme.colors.red74Prx}>
      <WarningIcon icon={warningOutline} size="small" />
      {children}
    </P>
  );
};

import { getStyledElement } from "@/helpers";
import { IonIcon } from "@ionic/react";

export const Icon = getStyledElement(IonIcon)({
  styles: ({ theme }) => ({
    "--border-radius": theme.sizes.buttonBorderRadius,
    "--box-shadow": "none",
    display: "inline-flex",
    "text-transform": "initial"
  })
});
